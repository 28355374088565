import { Router } from "../../routing/router";

import { AppLinks } from "./app-links";

/** Encapsulate router and app links to route users to various areas of the website */
export interface AppRouter {
  navigateToGetHomepage(): Promise<void>;
  navigateToTermsAndConditions(): Promise<void>;
  navigateToPrivacyPolicy(): Promise<void>;
  navigateToLoanSuiteApplication(applicationId: string): Promise<void>;
  navigateToReview(applicationId: string): Promise<void>;
  navigateToCheckout(applicationId: string): Promise<void>;
  navigateToConfirmationLink(applicationId: string): Promise<void>;
}

export class AppNavigator implements AppRouter {
  constructor(
    private readonly router: Router,
    private readonly appLinks: AppLinks
  ) {}
  async navigateToGetHomepage(): Promise<void> {
    this.router.go(this.appLinks.getHomepageLink());
  }
  async navigateToTermsAndConditions(): Promise<void> {
    this.router.go(this.appLinks.getTermsAndConditionsLink());
  }
  async navigateToPrivacyPolicy(): Promise<void> {
    this.router.go(this.appLinks.getPrivacyPolicyLink());
  }
  async navigateToLoanSuiteApplication(applicationId: string): Promise<void> {
    this.router.go(
      this.appLinks.getLoanSuiteApplicationLink() + "/" + applicationId
    );
  }
  async navigateToReview(applicationId: string): Promise<void> {
    this.router.go(this.appLinks.getReviewLink() + "/" + applicationId);
  }
  async navigateToCheckout(applicationId: string): Promise<void> {
    this.router.go(this.appLinks.getCheckoutLink() + "/" + applicationId);
  }
  async navigateToConfirmationLink(applicationId: string): Promise<void> {
    this.router.go(this.appLinks.getConfirmationLink() + "/" + applicationId);
  }
}
