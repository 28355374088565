import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";

const styles = require("./privacy-policy-page.css");

/**
 * The Privacy Policy page.
 */
@observer
export class PrivacyPolicyPage extends React.Component {
  /** Renders the component */
  render() {
    return (
      <div className={styles.container}>
        <GridContainer xsSpan={4} mdSpan={12}>
          <h1>Privacy Policy</h1>
          <p>At Krodok we are committed to protecting your privacy.</p>
          <p>
            Outlined below is our policy on handling your personal information.
            If you have any queries about this policy, or any concerns about how
            we collect, use or disclose your personal information, please
            contact us at 
            <a href="mailto:inquiries@krodok.com.au">inquiries@krodok.com.au</a>
          </p>
          <p>
            We are committed to complying with the Australian Privacy Principles
            in the Privacy Act. For more information about these, please visit
            the Office of the Australian Privacy Commissioner at 
            <a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>.
          </p>
          <h2>Collecting your personal information</h2>
          <p>
            We collect and hold personal information which is reasonably
            necessary to:
          </p>
          <ul>
            <li>
              <blockquote>
                <p>provide you with a relevant product or service</p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  retain the services of a third party provider to enable us to
                  provide the product or service
                </p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>manage and administer the product or service</p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  protect against fraud associated with the product or service
                </p>
              </blockquote>
            </li>
          </ul>
          <p>
            The personal information we collect will include your name (or the
            name of the organisation associated with you or the person you
            represent), your residential address, your email address, date of
            birth, and in some cases, your tax file number. We may also collect
            other personal information that is reasonably necessary for us to
            provide the product or service to you. 
          </p>
          <p>
            We will only collect your personal information from you yourself,
            unless it is unreasonable or impracticable to do so.
          </p>
          <p>
            When you fill out a form through our website, or when you contact us
            by telephone about your order, we will ask you to supply this
            personal information. In certain cases we may collect your personal
            information from third parties where it is reasonable and
            practicable to do so. For example, we may collect personal
            information from your professional representative (such as a legal
            adviser or accountant) who may be accessing our products and
            services on your behalf, or a publicly available source so long as
            it is lawful and fair for us to do so.
          </p>
          <h2>Your sensitive information</h2>
          <p>
            Sensitive information is information that reveals your racial or
            ethnic origin, political opinions, religious or philosophical
            beliefs or affiliations, membership of a professional or trade
            association, membership of a trade union, or other association,
            details of health, disability, sexual orientation or criminal
            record. We will not collect sensitive information about you unless
            it is reasonably necessary for us to provide you with the product or
            service you have ordered and we have your consent to do so or
            alternatively if we are required to by law.
          </p>
          <h2>Using and disclosing your personal information</h2>
          <p>
            We use your personal information for the purpose of supplying the
            product or service that you have ordered or enquired about.
          </p>
          <p>
            We disclose your personal information to third parties only to the
            extent necessary to supply you with the product or service you have
            ordered through Krodok’s website.
          </p>
          <p>
            Other third parties we may need to disclose your personal details to
            in the course of providing our products and services to you include
            your professional representative (such as a legal adviser or
            accountant) who may be accessing our products and services on your
            behalf, or our service providers including our software providers,
            couriers and IT consultants.
          </p>
          <p>
            Services made available through our website are delivered by third
            parties. By using any product or service originating from website,
            you are allowing us to share basic information with any third party
            with whom we have a partner relationship to deliver these products
            and services, necessary to facilitate provision of their products
            and services to you.
          </p>
          <p>
            Our partners are solely responsible for the content, products and
            services listed on their (Partner's) website(s). We are not
            responsible for, and do not accept any responsibility for the
            availability, contents, products, services or use of any third-party
            website, any website accessed from a third-party website or any
            changes or updates to such sites. We make no guarantees about the
            content or quality of the products or services outside our control.
            If you have purchased a package that includes any third party
            Services, the third party may contact you by email and/or phone with
            instructions on how to access your benefits and to keep you informed
            of their services. You may be required to accept additional terms
            located on the third-party website.
          </p>
          <p>
            Each of the third parties that we partner with is carefully selected
            and is only authorised to use your personal information in a secure
            way that’s necessary for them to perform their service to us, or
            directly for our customers.
          </p>
          <p>
            We will also disclose your personal information if we are required
            by law to do so.
          </p>
          <h2>Direct marketing</h2>
          <p>
            We may also use your personal information (but not sensitive
            information unless we have your consent) to notify you of other
            products and services (including third-party products and services)
            in which you may be interested. You can contact us at any time if
            you do not wish us to use your personal information in this way. You
            can also opt-out of direct marketing.
          </p>
          <h2>Disclosure to foreign countries</h2>
          <p>
            We do not currently disclose personal information to any
            organisation which is outside Australia. However, if we were to do
            so (for example, a foreign IT service provider), we would take
            reasonable steps to ensure that that service provider complies with
            the Australian privacy laws.
          </p>
          <h2>
            Keeping your personal information accurate, up-to-date and secure
          </h2>
          <p>
            At Krodok, we aim to make sure that the personal information we
            collect, use or disclose is accurate, complete and up-to-date. We
            also ask that when providing your personal information to us, you
            make sure it is accurate, and that you notify us if your personal
            details change so that we can update our records.
          </p>
          <p>
            We take all possible reasonable steps to protect your personal
            information from misuse, interference and loss, and from
            unauthorised access, modification or disclosure. This applies
            whether you supply personal information to us via our website, by
            telephone or by any other means.
          </p>
          <p>
            We will retain your personal details along with your order details,
            for our own internal record-keeping only and to comply with
            record-keeping requirements of government agencies or legislation
            such as taxation legislation. 
          </p>
          <h2>Credit card details</h2>
          <p>
            We do not store any credit card numbers. All payments are processed
            externally by the Commonwealth Bank.
          </p>
          <h2>Accessing your personal information</h2>
          <p>
            You can get access to your personal information held by Krodok by
            calling or emailing us. In most cases it is as simple as us printing
            out an electronic record of the information we hold. In some cases
            set out in the <em>Privacy Act 1988</em> (Cth), we may not be able
            to provide you with access to your personal information we hold,
            including where access might pose a serious threat to life or
            health, or might prejudice an ongoing investigation, or where we’re
            required by law not to give access.
          </p>
          <p>
            We will ask you for some personal identification to ensure we
            disclose the personal information to the right person.
          </p>
          <h2>Correcting your personal information</h2>
          <p>
            If you find that the personal information we hold about you is
            incorrect, please call or email us and we will take steps to correct
            it within a reasonable time. We will also notify any third party we
            have previously disclosed your incorrect personal information to, if
            you request us to do so.
          </p>
          <h2>Government related identifiers</h2>
          <p>
            In certain circumstances we need to collect government related
            identifiers such as your tax file number. However, we will not use
            or disclose this information other than to verify your identity for
            the purposes of supplying certain products and services to you, or
            to fulfil our obligations to the relevant government agency, or if
            required by law, or if you have authorised our use or disclosure.
          </p>
          <h2>Dealing with us anonymously or using a pseudonym</h2>
          <p>
            Because of the nature of our products and services, you will
            generally need to use your real name when ordering with us. 
            However, you can deal with us anonymously or using a pseudonym where
            it is lawful and practicable to do so, for example, if you are
            simply making an inquiry.
          </p>
          <h2>Resolving your privacy issues</h2>
          <p>
            As noted above, if you have any concerns or issues about this policy
            or our handling of your personal information, then please contact us
            at <a href="mailto:inquiries@krodok.com">inquiries@krodok.com</a>{" "}
            and we will do our best to resolve your issue. If we need to
            investigate further, we will keep you advised of our progress.
          </p>
        </GridContainer>
      </div>
    );
  }
}
