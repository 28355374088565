import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

const styles = require("./responsive-image.css");

/** Props for the component */
export interface ResponsiveImageProps {
  /** Additional classes on the root element */
  className?: string;
  /** Additional classes on the `img` element */
  imgClassName?: string;
  /** Image sources for each screen size and density */
  src: string;
}

/**
 * Renders the appropriate image depending on the screen size and density.
 * The picture component does not support IE 11 due to it using the
 * CSS property object-fit: cover to resize the image which is not supported in IE 11.
 * In order to add IE 11 support, this class is handled differently in IE
 * - A div with a background image will be used as a replacement
 */
@observer
export class ResponsiveImage extends React.Component<ResponsiveImageProps> {
  /** Renders the component */
  render() {
    return (
      <div className={classnames(styles.container, this.props.className)}>
        <img
          className={classnames(styles.image, this.props.imgClassName)}
          src={this.props.src}
        />
        <div
          className={classnames(styles.ieContainer, this.props.imgClassName)}
        >
          <div
            className={classnames(styles.ieImg)}
            style={{ backgroundImage: `url(${this.props.src})` }}
          />
        </div>
      </div>
    );
  }
}
