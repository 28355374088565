import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";

const styles = require("./terms-and-conditions-page.css");

/**
 * The Terms And Conditions page.
 */
@observer
export class TermsAndConditionsPage extends React.Component {
  /** Renders the component */
  render() {
    return (
      <div className={styles.container}>
        <GridContainer xsSpan={4} mdSpan={12}>
          <h1>Terms & Conditions</h1>
          <p>
            These website terms of use outline how you are able to use our
            website and content, as well as the other resources found on our
            website. We hope that you find them to be useful, helpful and
            insightful and we are happy to answer any questions you have about
            them. We have developed our content to help you properly protect
            money invested in your business as a registered secured loan. In
            these website terms of use, <strong>we</strong>,  
            <strong>us </strong>
             or <strong>our</strong> means Krodok Pty Ltd ABN 14 640 079 670.
          </p>
          <h2>Consent to terms &amp; conditions</h2>
          <p>
            If you access or order or download our documents, you are taken to
            have agreed to these website terms of use and our privacy policy,
            found below (<strong>Terms</strong>). We ask you to please read
            these Terms carefully. If you don’t agree with our Terms, then you
            must cease using our website immediately.
          </p>
          <h2>Authority</h2>
          <p>
            You confirm that you are authorised to act on behalf of the
            “lender”, as that term is defined in the Loan Deed, including each
            party comprising the lender (<strong>Lender</strong>) and on behalf
            of the “borrower” as that term is defined in the Loan Deed and to
            bind each of those parties.
          </p>
          <h2>No legal or financial advice</h2>
          <p>
            We do not provide legal, financial, accounting, tax, business, or
            other professional advice. We provide various documents for loans
            made by shareholders and related parties to their companies. We are
            not lawyers, accountants, tax planners or financial advisers.
          </p>
          <p>
            We cannot and do not advise you on whether our products and services
            are appropriate for your specific needs, or whether they will
            achieve a particular purpose. Equally, we cannot provide ongoing
            advice about your documents or your business once those documents
            have been created or executed.
          </p>
          <p>
            You acknowledge that you have either visited your lawyer, accountant
            or other professional adviser for advice and a clear understanding
            of what you are ordering from us, or that you have chosen not to do
            so, but accept the resulting risk that our products or services
            might not meet your individual needs.
          </p>
          <p>
            On parts of our website, we may post articles written by third
            parties, including accountants, lawyers or other business advisers.
            This material is for information only, is general in nature, and is
            not a substitute for professional advice tailored to your individual
            needs. We do not endorse the accuracy of this information or the
            views expressed in these articles.
          </p>
          <h2>You are responsible for the information you input</h2>
          <p>
            The documents that you order on our website, and some of the
            information in those documents, is dependent on the information you
            provide in the order form or on your applications. We are not and
            will not be responsible if you supply incorrect or false
            information, if you misunderstand a question or information on our
            online order form or on other parts of our website, or if you
            otherwise order a document that does not end up being appropriate
            for the outcome that you desire.
          </p>
          <p>
            You should carefully check all of the information you have submitted
            on our website before completing the online order form.
          </p>
          <h2>Standard form documents</h2>
          <p>
            Our documents are standard-form documents. Accordingly, they may not
            be suitable for your individual needs or circumstances.
          </p>
          <p>
            You should seek professional advice about whether you need our
            documents, or what type of documents you need before ordering. By
            ordering products or services from us, you agree that you have not
            relied on any representation or warranty from Krodok about the
            suitability of any of our documents for your needs and
            circumstances, and to the extent permitted by law, Krodok has no
            liability to you in relation to any such representation or warranty.
          </p>
          <h2>Warranties and disclaimers</h2>
          <p>
            To the maximum extent permitted by law, we make no representations
            or warranties about our website or the Content, including that:
          </p>
          <ul>
            <li>
              <p>
                they are complete, accurate, reliable, up-to-date and suitable
                for any particular purpose;
              </p>
            </li>
            <li>
              <p>
                access will be uninterrupted, error-free or free from viruses;
                or
              </p>
            </li>
            <li>
              <p>our website will be secure.</p>
            </li>
          </ul>
          <p>
            You read, use and act on our website and the Content at your own
            risk.
          </p>
          <h2>Intellectual property</h2>
          <p>
            Unless we indicate otherwise, please note that we own or licence all
            rights, title and interest (including intellectual property rights)
            in our website and all of our content. Your use of our website and
            your use of and access to any of our content does not grant or
            transfer to you any rights, title or interest in relation to our
            website or our Content.
          </p>
          <p>
            So that you can use and access our website, we grant you a
            non-exclusive, revocable, worldwide, non-transferable licence to use
            our website in accordance with these Terms.  All other uses of our
            website are prohibited unless we give you our written consent.
          </p>
          <p>
            You must not breach any intellectual property rights connected with
            our website or our content, including (without limitation) altering
            or modifying any of our content or creating derivative works from
            the content.
          </p>
          <h2>Restrictions on use </h2>
          <p>
            If you order any documents from Krodok, we supply it to you based on
            the understanding that we have copyright in each of the documents.
            That means the text and layout of each of the documents belongs to
            Krodok and you must only use the document according to the licence
            we grant to you, which is outlined below:
          </p>
          <ul>
            <li>
              <p>
                you agree to use the documents only for the purpose documenting
                and registering a loan to your company;
              </p>
            </li>
            <li>
              <p>
                you cannot use any document (or parts of any document) to create
                another document;
              </p>
            </li>
            <li>
              <p>
                you cannot supply (for sale or otherwise) the document (or part
                of it) to someone else unless you are a professional adviser and
                you are ordering the documents on behalf of your client;
              </p>
            </li>
            <li>
              <p>
                you are prohibited from using our website and the Content in any
                way that competes with our business.
              </p>
            </li>
            <li>
              <p>
                you will not alter the document once it is finalised, except to
                sign and date it if applicable, otherwise in so amending any
                document, you acknowledge that you do so at your own risk and
                the effect of that document may change and may not be
                appropriate for, or achieve, the outcome you desire;
              </p>
            </li>
            <li>
              <p>
                you will not copy the document except for your own personal
                purposes;
              </p>
            </li>
            <li>
              <blockquote>
                <p>
                  you will not remove any Krodok branding from any of the
                  documents;
                </p>
              </blockquote>
            </li>
            <li>
              <p>
                you also acknowledge that the content of our website, including
                the text on each page, the layout and order forms (including all
                underlying computer programming and code), as well as the trade
                mark Krodok, is the intellectual property of Krodok, and you
                agree not to use any of this content for any purpose other than
                with Krodok’s express written permission.
              </p>
            </li>
          </ul>
          <h2>Indemnity</h2>
          <p>
            You agree to indemnify Krodok against any loss, damage or other
            liability:
          </p>
          <ul>
            <li>
              <p>that we suffer because you have breached these Terms, or</p>
            </li>
            <li>
              <p>
                that you or a third<sup> </sup>party suffers because of the
                information you supplied in an order form, or because you did
                not obtain professional advice about whether a product or
                service we supplied meets your individual needs and
                circumstances.
              </p>
            </li>
          </ul>
          <h2>Appointment as agent</h2>
          <p>
            By confirming that the documents you have ordered have been
            executed, you appoint Krodok, and its representatives, to act as the
            agent of the Lender for the purpose of registering a security
            interest on the register maintained pursuant to the{" "}
            <em>Personal Property Securities Act 2009</em> (Cth)
          </p>
          <h2>Limitation of our liability</h2>
          <p>
            To the extent that the law allows us to do so, our liability to you
            for any failure on our part (including negligence) is limited to
            either replacing the documents, supplying equivalent documents,
            amending the documents, or paying the costs of replacement, supply
            or amendment.
          </p>
          <p>
            In particular, our liability to you does not include any indirect or
            consequential loss, such as lost profit or revenue, missed business
            opportunity, damage to goodwill, or any expenses you incurred
            because of our failure.
          </p>
          <p>
            Australia’s consumer protection laws imply various guarantees into
            contracts for goods and services ordinarily acquired for personal,
            household or domestic use, including that goods will be of
            acceptable quality and that services will be provided with due care
            and skill and within a reasonable time. Our liability limit is not
            intended to restrict the operation of the Australian consumer
            protection laws if they are applicable in our agreement.
          </p>
          <h2>Links to other websites &amp; service providers</h2>
          <p>
            Where we provide links to other websites and service providers for
            general information and convenience only, and we do not vouch for
            their relevance or accuracy. We do not endorse any particular
            website or service provider. You need to form your own view of those
            websites or service providers and access them at your own risk.
            Krodok may have a fee for referral arrangement with one or more of
            these service providers.
          </p>
          <h2>Use of client logos in marketing</h2>
          <p>
            Krodok may use customer names or logos for marketing purposes
            including, but not limited to, on the Krodok website and in
            marketing materials. “Customers” include those directly ordering
            through Krodok and clients/affiliates of those placing the order.
          </p>
          <p>
            Should permission not be granted, simply  
            <a href="mailto:inquiries@krodok.com.au">
              <em> email us</em>
            </a>
            .
          </p>
          <h2>Payment</h2>
          <p>
            We require cleared funds from private &quot;cash&quot; customers
            before we process the order. We accept payment via credit card or
            debit card. Funds sent by bank transfer will need to be cleared
            before orders are processed which may take up to four business days.
          </p>
          <p>
            For payments made by Amex, we may charge an administration fee of
            1.93%. Visa and MasterCard will not be subject to fees.
          </p>
          <h2>Refunds &amp; returns</h2>
          <p>
            Products and services supplied by Krodok are created to meet the
            specific requirements of each purchaser and/or client. Due to the
            nature of these products and services, they cannot be re-sold to
            another purchaser or client. We do not provide refunds for products
            or services we supply in good faith according to your request and
            the information that you have provided.
          </p>
          <p>
            If you return a product or service to Krodok due to an error on our
            part, we will amend the error and return the product or service as
            soon as is practicable.
          </p>
          <p>
            If you return a product or service due to an error on your part, and
            we have complied with your original instructions, we may at our
            discretion levy an additional charge that fairly reflects the time
            and expertise required to amend the error. Krodok is not responsible
            for any error or loss due to your mistake or misunderstanding of the
            law.
          </p>
          <p>
            If you have any concerns relating to Krodok refund and return
            policy, please contact us and our staff will be happy to discuss
            your concerns and attempt to resolve them.
          </p>
          <h2>Varying the Terms</h2>
          <p>
            We may, at any time and at our discretion, vary the Terms by
            publishing the varied terms on our website. We recommend you check
            our website regularly to ensure you are aware of our current terms.
            The content on our website is subject to change without notice.
          </p>
          <h2>Discontinuing our website</h2>
          <p>
            We have a right to discontinue this website. This can be at any
            time, and may be without notice. We may also exclude any person from
            using our website, at any time and at our sole discretion. We are
            not responsible for any liability you may suffer arising from or in
            connection with any such discontinuance or exclusion.
          </p>
          <h2>Jurisdiction</h2>
          <p>
            These Terms of use are governed by New South Wales law. You and we
            agree that we will settle any dispute about them by first trying to
            resolve the dispute between ourselves, or failing that, exclusively
            in the courts of New South Wales.
          </p>
          <p>
            Krodok Pty Ltd ABN 14 640 079 670 is an Australian based business in
            Sydney.
          </p>
        </GridContainer>
      </div>
    );
  }
}
