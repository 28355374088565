import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { assertNever, Unknown } from "../../../util/types";
import { GridContainer } from "../../common/grid-container/grid-container";
import { Button } from "../button/button";
import { CheckItem, CheckItemProps } from "../check-item/check-item";
import {
  InformationalQuestion,
  InformationalQuestionProps
} from "../informational-question/informational-question";
import {
  MultiSelectQuestion,
  MultiSelectQuestionProps
} from "../multi-select-question/multi-select-question";
import {
  MultiTextQuestion,
  MultiTextQuestionProps
} from "../multi-text-question/multi-text-question";
import {
  SelectQuestion,
  SelectQuestionProps
} from "../select-question/select-question";
import {
  TextQuestion,
  TextQuestionProps
} from "../text-question/text-question";

const styles = require("./questions-list.css");

/** Whether to suppress auto scrolling for testing */
const SUPPRESS_AUTO_SCROLLING = false;

/** Props for the component */
export interface QuestionsListProps {
  /** Additional classes to apply */
  className?: string;
  /** List of questions */
  questions: Question[];
  /** First unanswered question */
  firstUnansweredQuestion?: Question;
  /** First errored question */
  firstErroredQuestion?: Question;
  /** Callback to notify parent that the user has answered all questions */
  onCompletedAllQuestions(): void;
  /** Callback to register the ref to the first unanswered question marker */
  onFirstUnansweredQuestionMarkerRef?(ref: Element | null): void;
  /** Callback to register the ref to the first errored question marker */
  onFirstErroredQuestionMarkerRef?(ref: Element | null): void;
  /** Tail content for the last question */
  lastQuestionTailContent: JSX.Element;
}

export type Question =
  | {
      kind: "select";
      value: SelectQuestionProps<Unknown>;
    }
  | {
      kind: "text";
      value: TextQuestionProps;
    }
  | {
      kind: "multi-select";
      value: MultiSelectQuestionProps<Unknown>;
    }
  | {
      kind: "multi-text";
      value: {
        props: MultiTextQuestionProps;
        secondaryButtonProps?: SecondaryButtonProps;
      };
    }
  | {
      kind: "check-item";
      value: CheckItemProps;
    }
  | {
      kind: "informational";
      value: InformationalQuestionProps;
    };

export interface SecondaryButtonProps {
  variant?: "primary" | "secondary" | "tertiary" | "round" | "outline";
  children: string;
  onClick(): Promise<void>;
}

/**
 * List of questions.
 */
@observer
export class QuestionsList extends React.Component<QuestionsListProps> {
  /** Ref to mark the first unanswered question */
  firstUnasweredQuestionMarkerRef: Element | null;
  /** Ref to mark the first errored question */
  firstErroredQuestionMarkerRef: Element | null;

  /** Renders a next button if condition is satisfied and it's not the last question */
  maybeRenderNextButton(
    isLastQuestion: boolean,
    visible: boolean,
    enabled: boolean
  ) {
    if (!isLastQuestion && visible) {
      return (
        <GridContainer xsSpan={2} smSpan={2} mdSpan={2}>
          <Button
            className={styles.nextButton}
            disabled={!enabled}
            onClick={async () => this.scrollToFirstUnansweredQuestionOrEnd()}
          >
            Next
          </Button>
        </GridContainer>
      );
    } else {
      return null;
    }
  }

  /** Renders a select question */
  renderSelectQuestion(
    props: SelectQuestionProps<Unknown>,
    isLastQuestion: boolean
  ) {
    if (props.customOption) {
      return (
        <>
          <SelectQuestion
            {...props}
            onSelect={async (value, isCustom) => {
              await props.onSelect(value, isCustom);
              if (!isCustom) {
                this.scrollToFirstUnansweredQuestionOrEnd();
              }
            }}
          />
          {this.maybeRenderNextButton(
            isLastQuestion,
            props.customOption.selected,
            props.customOption.selected &&
              props.customOption.textValue.length > 0
          )}
        </>
      );
    } else {
      return (
        <>
          <SelectQuestion
            {...props}
            onSelect={async (value, isCustom) => {
              await props.onSelect(value, isCustom);
              this.scrollToFirstUnansweredQuestionOrEnd();
            }}
          />
        </>
      );
    }
  }

  /** Renders a text question */
  renderTextQuestion(props: TextQuestionProps, isLastQuestion: boolean) {
    return (
      <>
        <TextQuestion {...props} />
        {this.maybeRenderNextButton(
          isLastQuestion,
          true,
          props.value.length > 0
        )}
      </>
    );
  }

  /** Renders a multi-select question */
  renderMultiSelectQuestion(
    props: MultiSelectQuestionProps<Unknown>,
    isLastQuestion: boolean
  ) {
    return (
      <>
        <MultiSelectQuestion {...props} />
        {this.maybeRenderNextButton(
          isLastQuestion,
          true,
          !!(
            props.options.some(option => option.selected) ||
            (props.noneOption && props.noneOption.selected) ||
            (props.alternativeOption && props.alternativeOption.selected) ||
            (props.customOption &&
              props.customOption.selected &&
              props.customOption.textValue.length > 0)
          )
        )}
      </>
    );
  }

  /** Renders a multi-text question */
  renderMultiTextQuestion(
    props: MultiTextQuestionProps,
    isLastQuestion: boolean,
    /** Secondary button to render next to the next button */
    secondaryButtonProps?: SecondaryButtonProps
  ) {
    const valueEntered = props.inputs.every(input => input.value.length > 0);
    if (secondaryButtonProps) {
      return (
        <>
          <MultiTextQuestion {...props} />
          {valueEntered ? (
            // NOTE: Custom rendering of next button
            <div className={styles.nextButton}>
              <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
                <Button
                  variant={secondaryButtonProps.variant}
                  onClick={secondaryButtonProps.onClick}
                >
                  {secondaryButtonProps.children}
                </Button>
              </GridContainer>
              <GridContainer xsSpan={2} smSpan={2} mdSpan={2}>
                <Button
                  className={styles.secondaryButtonNext}
                  onClick={async () =>
                    this.scrollToFirstUnansweredQuestionOrEnd()
                  }
                >
                  Next
                </Button>
              </GridContainer>
            </div>
          ) : (
            undefined
          )}
        </>
      );
    } else {
      return (
        <>
          <MultiTextQuestion {...props} />
          {this.maybeRenderNextButton(isLastQuestion, true, valueEntered)}
        </>
      );
    }
  }

  renderCheckItemQuestion(props: CheckItemProps, isLastQuestion: boolean) {
    return (
      <>
        <CheckItem {...props}>
          {this.maybeRenderNextButton(isLastQuestion, true, props.selected)}
        </CheckItem>
      </>
    );
  }

  renderInformationalQuestion(props: InformationalQuestionProps) {
    return (
      <>
        <InformationalQuestion
          {...props}
          onClick={async () => {
            if (!props.seen) {
              await props.onClick();
              this.scrollToFirstUnansweredQuestionOrEnd();
            }
          }}
        />
      </>
    );
  }

  /** Renders a single question */
  renderQuestion(question: Question, isLastQuestion: boolean) {
    let questionContent: JSX.Element;
    let key: string = "";

    if (question.kind === "select") {
      key = question.value.questionText;
      questionContent = this.renderSelectQuestion(
        question.value,
        isLastQuestion
      );
    } else if (question.kind === "text") {
      key = question.value.questionText;
      questionContent = this.renderTextQuestion(question.value, isLastQuestion);
    } else if (question.kind === "multi-select") {
      key = question.value.questionText;
      questionContent = this.renderMultiSelectQuestion(
        question.value,
        isLastQuestion
      );
    } else if (question.kind === "multi-text") {
      key = question.value.props.questionText;
      questionContent = this.renderMultiTextQuestion(
        question.value.props,
        isLastQuestion,
        question.value.secondaryButtonProps
      );
    } else if (question.kind === "check-item") {
      key = question.value.label;
      questionContent = this.renderCheckItemQuestion(
        question.value,
        isLastQuestion
      );
    } else if (question.kind === "informational") {
      key = question.value.questionText;
      questionContent = this.renderInformationalQuestion(question.value);
    } else {
      return assertNever(question, "unknown question type");
    }

    return (
      <div key={key} className={styles.question}>
        {questionContent}
        {isLastQuestion && this.props.lastQuestionTailContent}
      </div>
    );
  }

  /** Renders a marker if provided with the first unanswered question */
  maybeRenderFirstUnansweredQuestionMarker(question: Question) {
    if (
      this.props.firstUnansweredQuestion &&
      Object.is(this.props.firstUnansweredQuestion, question)
    ) {
      return (
        <div
          key="firstUnansweredQuestionMarker"
          ref={this.onFirstUnansweredQuestionMarkerRef}
        />
      );
    } else {
      return undefined;
    }
  }

  /** Renders a marker if provided with the first errored question */
  maybeRenderFirstErroredQuestionMarker(question: Question) {
    let firstErroredQuestionText;
    let questionText;
    switch (question.kind) {
      case "multi-text":
        questionText = question.value.props.questionText;
        break;
      case "check-item":
        questionText = question.value.label;
        break;
      default:
        questionText = question.value.questionText;
    }

    if (this.props.firstErroredQuestion) {
      switch (this.props.firstErroredQuestion.kind) {
        case "multi-text":
          firstErroredQuestionText = this.props.firstErroredQuestion.value.props
            .questionText;
          break;
        case "check-item":
          firstErroredQuestionText = this.props.firstErroredQuestion.value
            .label;
          break;
        default:
          firstErroredQuestionText = this.props.firstErroredQuestion.value
            .questionText;
      }
    }
    if (
      this.props.firstErroredQuestion &&
      questionText === firstErroredQuestionText
    ) {
      return (
        <div
          key="firstErroredQuestionMarker"
          ref={this.onFirstErroredQuestionMarkerRef}
        />
      );
    } else {
      return undefined;
    }
  }

  /** Renders the component */
  render() {
    return (
      <div className={classnames(styles.container, this.props.className)}>
        {this.props.questions.map((question, index) => {
          const isLastQuestion = index === this.props.questions.length - 1;
          return [
            this.maybeRenderFirstUnansweredQuestionMarker(question),
            this.maybeRenderFirstErroredQuestionMarker(question),
            this.renderQuestion(question, isLastQuestion)
          ];
        })}
      </div>
    );
  }

  /*TODO(HARRY): Scrolling should be handled by the containing component */
  /** Callback to receive the first unanswered question marker ref */
  onFirstUnansweredQuestionMarkerRef = (ref: Element | null) => {
    this.firstUnasweredQuestionMarkerRef = ref;
    if (this.props.onFirstUnansweredQuestionMarkerRef) {
      this.props.onFirstUnansweredQuestionMarkerRef(ref);
    }
  };

  /** Callback to receive the first unanswered question marker ref */
  onFirstErroredQuestionMarkerRef = (ref: Element | null) => {
    this.firstErroredQuestionMarkerRef = ref;
    if (this.props.onFirstErroredQuestionMarkerRef) {
      this.props.onFirstErroredQuestionMarkerRef(ref);
    }
  };

  /** Scrolls to the first unanswered question */
  scrollToFirstUnansweredQuestionOrEnd() {
    if (SUPPRESS_AUTO_SCROLLING) {
      return;
    }
    window.setTimeout(() => {
      if (this.firstUnasweredQuestionMarkerRef) {
        // Scroll to the first unanswered question
        const marker = ReactDOM.findDOMNode(
          this.firstUnasweredQuestionMarkerRef
        );
        if (marker !== null && marker instanceof Element) {
          marker.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else if (this.firstErroredQuestionMarkerRef) {
        // Scroll to the first errored question
        const marker = ReactDOM.findDOMNode(this.firstErroredQuestionMarkerRef);
        if (marker !== null && marker instanceof Element) {
          marker.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        // All questions complete - notify parent
        this.props.onCompletedAllQuestions();
      }
    }, 0);
  }
}
