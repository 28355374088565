import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { CheckItem } from "../check-item/check-item";
import { SelectOption } from "../select-option/select-option";
import { CustomOption, PresetOption } from "../select-question/select-question";
import { TextInput } from "../text-input/text-input";

const styles = require("./multi-select-question.css");

/** Question definition */
export interface MultiSelectQuestionProps<T> {
  /** Additional classes to apply */
  className?: string;
  /** Text of the question */
  questionText: string;
  /** Hint text for the question */
  hintText?: string;
  /** Preset options */
  options: PresetOption<T>[];
  /** Custom option definition */
  customOption?: CustomOption<T>;
  /** Alternative option definition */
  alternativeOption?: AlternativeOption;
  /** "None" option definition */
  noneOption?: NoneOption;
  /** Callback when an option selection is changed */
  onChangeSelection(option: T, selected: boolean): void;
}

/**
 * An alternative option that is mutually exclusive to the list of options
 */
export interface AlternativeOption {
  /** Label of the option */
  label: string;
  /** Whether it is selected */
  selected: boolean;
  /** Callback when the selection changes */
  onChange(selected: boolean): void;
}

/**
 * Special "none" option to explicitly indicate that there should be no selected options.
 */
export interface NoneOption {
  /** Whether it is selected */
  selected: boolean;
  /** Callback when the selection changes */
  onChange(selected: boolean): void;
}

/**
 * A question with a multiple answers chosen from a selection with an optional
 * custom option. Also provides a mutually exclusive alternative option, suited
 * for "do not want to say".
 */
@observer
export class MultiSelectQuestion<T> extends React.Component<
  MultiSelectQuestionProps<T>
> {
  /** Renders the custom option */
  renderCustomOption(option: CustomOption<T>) {
    return (
      <div>
        <CheckItem
          label={option.label}
          selected={option.selected}
          onChange={selected => {
            const value = option.makeCustomOptionValue(option.textValue);
            this.props.onChangeSelection(value, selected);
          }}
        />
        {option.selected ? (
          <TextInput
            className={styles.customTextInput}
            type="text"
            value={option.textValue}
            placeholder="Please enter"
            onChange={value => {
              this.props.onChangeSelection(
                option.makeCustomOptionValue(value),
                true
              );
            }}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  /** Renders the alternative option */
  renderAlternativeOption(option: AlternativeOption) {
    return (
      <SelectOption
        className={styles.option}
        label={option.label}
        selected={option.selected}
        onChange={option.onChange}
      />
    );
  }

  /** Renders the question */
  render() {
    return (
      <div className={this.props.className}>
        <div>
          <GridContainer xsSpan={4}>
            <div className={styles.questionText}>{this.props.questionText}</div>
            {this.props.hintText && (
              <div className={styles.hintText}>{this.props.hintText}</div>
            )}
          </GridContainer>
        </div>
        <div>
          <GridContainer xsSpan={4}>
            {this.props.options.map(option => (
              <CheckItem
                key={option.label}
                label={option.label}
                hintText={option.hintText}
                selected={option.selected}
                onChange={selected => {
                  this.props.onChangeSelection(option.value, selected);
                }}
              />
            ))}
          </GridContainer>
        </div>
        <div>
          <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
            {this.props.customOption
              ? this.renderCustomOption(this.props.customOption)
              : null}
          </GridContainer>
        </div>
        {this.props.noneOption && (
          <div>
            <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
              <SelectOption
                className={styles.option}
                label="None"
                selected={this.props.noneOption.selected}
                onChange={this.props.noneOption.onChange}
              />
            </GridContainer>
          </div>
        )}
        <div>
          <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
            {this.props.alternativeOption
              ? this.renderAlternativeOption(this.props.alternativeOption)
              : null}
          </GridContainer>
        </div>
      </div>
    );
  }
}
