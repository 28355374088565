import classnames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { IconProps } from "../../common/icon/icons";

const styles = require("./button.css");

/** Props for a button component */
export interface ButtonProps {
  /** Additional classes to apply */
  className?: string;
  /** Classes to apply to the inner text element */
  textClassName?: string;
  /** Text to render */
  children: string;
  /** Variant of button, otherwise it's primary */
  variant?: "primary" | "secondary" | "tertiary" | "round" | "outline";
  /** Button icon */
  Icon?: React.ComponentType<IconProps>;
  /** Icon position */
  iconPosition?: "left" | "right";
  /** Size of the button, otherwise it's regular */
  size?: "regular" | "small";
  /** Callback on click */
  onClick?(): Promise<void>;
  /** Href link to follow */
  href?: string;
  /** Target for the href link */
  hrefTarget?: string;
  /** Whether to render as in progress mode - exposed for storybook testing */
  alwaysInProgress?: boolean;
  /** Button disabled */
  disabled?: boolean;
}

/**
 * Button with different variants.
 */
@observer
export class Button extends React.Component<ButtonProps> {
  @observable.ref isInProgress: boolean = !!this.props.alwaysInProgress;

  /** Renders the button element with desired onClick behavior */
  renderButton(props: {
    applyClassName?: boolean;
    onClick?: (() => Promise<void>) | undefined;
  }) {
    const className = classnames(
      styles.button,
      this.isInProgress && styles.inProgress,
      this.props.variant === "primary" || !this.props.variant
        ? styles.primary
        : null,
      this.props.variant === "secondary" && styles.secondary,
      this.props.variant === "tertiary" && styles.tertiary,
      this.props.variant === "round" && styles.round,
      this.props.variant === "outline" && styles.outline,
      this.props.size === "regular" || !this.props.size ? styles.regular : null,
      this.props.size === "small" ? styles.small : null,
      props.applyClassName && this.props.className
    );

    const iconPosition = this.props.iconPosition || "left";
    return (
      <button
        type="button"
        disabled={!!this.props.disabled}
        onClick={props.onClick}
        className={className}
      >
        {this.isInProgress ? <div className={styles.spinner} /> : undefined}
        <div className={styles.content}>
          {this.props.Icon && iconPosition === "left" ? (
            <this.props.Icon className={styles.leftIcon} size="xsmall" />
          ) : (
            undefined
          )}
          <span
            className={classnames(
              styles.contentLabel,
              this.props.textClassName
            )}
          >
            {this.props.children}
          </span>
          {this.props.Icon && iconPosition === "right" ? (
            <this.props.Icon className={styles.rightIcon} size="xsmall" />
          ) : (
            undefined
          )}
        </div>
      </button>
    );
  }

  /** Renders the component */
  render() {
    // Check for `href` first because there may also be an `onClick` handler
    if (this.props.href) {
      return (
        <div className={this.props.className} onClick={this.props.onClick}>
          <a
            href={this.props.disabled ? undefined : this.props.href}
            target={this.props.hrefTarget}
          >
            {this.renderButton({})}
          </a>
        </div>
      );
    } else if (this.props.onClick) {
      return this.renderButton({
        applyClassName: true,
        onClick: this.onClick
      });
    } else {
      return this.renderButton({ applyClassName: true });
    }
  }

  /**
   * Click handler which shows a spinner while the action is in progress.
   * If button is already in progress then callback is not called.
   */
  onClick = async () => {
    if (this.isInProgress) {
      return;
    }
    if (!this.props.onClick) {
      return;
    }

    this.isInProgress = true;
    try {
      await this.props.onClick();
    } catch (err) {
      // Rethrow
      throw err;
    } finally {
      this.isInProgress = false;
    }
  };
}
