import { action, observable } from "mobx";

import { Question } from "../ui/widgets/questions-list/questions-list";

import { ApplicationStore } from "./application-store";
import { AppNavigator } from "./shared/app-router";

/** Type used for string values */
export interface SelectedAndError {
  /** Selected */
  selected: boolean;
  /** Error message related to entered value */
  error?: string;
}

/** The model of the loan suite form */
export class TermsAndConditions {
  @observable.deep authorisedCheck: SelectedAndError = {
    selected: false
  };
  @observable.deep termsAndConditionsCheck: SelectedAndError = {
    selected: false
  };
  @observable.deep nonLegalAdviceCheck: SelectedAndError = {
    selected: false
  };
  @observable.deep correctInformationCheck: SelectedAndError = {
    selected: false
  };
  @observable.deep appointedKrodokCheck: SelectedAndError = {
    selected: false
  };
}

export class TocStore {
  @observable.deep applicationStore: ApplicationStore | undefined;
  /** In progress loan suite form data */
  termsAndConditions: TermsAndConditions = new TermsAndConditions();

  // for the data to send to the server
  constructor(private readonly appNavigator: AppNavigator) {}

  @action
  setApplication = (applicationStore: ApplicationStore) => {
    this.applicationStore = applicationStore;
  };

  /** Change callbacks */
  /** Callback to update dummy */
  onChangeAuthorisedCheck = (selected: boolean) => {
    this.termsAndConditions.authorisedCheck.selected = selected;
  };
  onChangeTermsAndConditionsCheck = (selected: boolean) => {
    this.termsAndConditions.termsAndConditionsCheck.selected = selected;
  };
  onChangeNonLegalAdviceCheck = (selected: boolean) => {
    this.termsAndConditions.nonLegalAdviceCheck.selected = selected;
  };
  onChangeCorrectInformationCheck = (selected: boolean) => {
    this.termsAndConditions.correctInformationCheck.selected = selected;
  };
  onChangeAppointedKrodokCheck = (selected: boolean) => {
    this.termsAndConditions.appointedKrodokCheck.selected = selected;
  };

  getQuestionsSet(): Question[] {
    return [
      this.getAuthorisedAgreementCheckQuestion(),
      this.getTermsAndConditionsLinkCheckQuestion(),
      this.getNonLegalAdviceCheckQuestion(),
      this.getCorrectInformationCheckQuestion(),
      this.getAppointedKrodokCheckQuestion()
    ];
  }

  private getAuthorisedAgreementCheckQuestion(): Question {
    return {
      kind: "check-item",
      value: {
        label:
          "I am authorised to enter this agreement with Krodok on behalf of the Lender and Borrower",
        selected: this.termsAndConditions.authorisedCheck.selected,
        onChange: this.onChangeAuthorisedCheck
      }
    };
  }

  private getTermsAndConditionsLinkCheckQuestion(): Question {
    return {
      kind: "check-item",
      value: {
        label: "I have read and agree to the ",
        selected: this.termsAndConditions.termsAndConditionsCheck.selected,
        onChange: this.onChangeTermsAndConditionsCheck,
        inlineLink: "/terms-and-conditions",
        inlineLinkText: "Terms and Conditions"
      }
    };
  }

  private getNonLegalAdviceCheckQuestion(): Question {
    return {
      kind: "check-item",
      value: {
        label:
          "I acknowledge that Krodok has not given any legal advice on this website and I have not relied on anything on this website as legal advice",
        selected: this.termsAndConditions.nonLegalAdviceCheck.selected,
        onChange: this.onChangeNonLegalAdviceCheck
      }
    };
  }

  private getCorrectInformationCheckQuestion(): Question {
    return {
      kind: "check-item",
      value: {
        label:
          "I confirm that the information that I have provided is correct and I hold harmless Krodok as a result of any loss or damage arising from the information provided",
        selected: this.termsAndConditions.correctInformationCheck.selected,
        onChange: this.onChangeCorrectInformationCheck
      }
    };
  }

  private getAppointedKrodokCheckQuestion(): Question {
    return {
      kind: "check-item",
      value: {
        label:
          "I confirm that Krodok is appointed as the agent of the Lender for the purpose of registering a security interest on the register maintained pursuant to the Personal Property Securities Act 2009 (Cth)",
        selected: this.termsAndConditions.appointedKrodokCheck.selected,
        onChange: this.onChangeAppointedKrodokCheck
      }
    };
  }

  /** Callback to submit the loan suite application form */
  // tslint:disable-next-line: no-async-without-await
  onSubmit = async (): Promise<void> => {
    //perform type checking on response, and update error states as necessary.
    this.termsAndConditions.authorisedCheck.error =
      "You must agree to all terms and conditions before progressing";
    if (this.allQuestionsAnswered() && this.applicationStore !== undefined && this.applicationStore.applicationId) {
      const application = this.applicationStore.getApplication();
      if(!application) {
        throw new Error("Application not found for id")
      }
      await this.applicationStore.updateApplication({
        companyType: application.companyType,
        contactEmail: application?.contactEmail,
        applicationData: application?.applicationData,
        acceptedTOS: true
      });
      await this.appNavigator.navigateToCheckout(this.applicationStore.applicationId);
    }
  };

  allQuestionsAnswered = (): boolean => {
    for (const element of Object.keys(this.termsAndConditions)) {
      if (this.termsAndConditions[element].selected=== false) {
        return false;
      }
    }
    return true;
  };
}
