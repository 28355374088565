import { action, observable } from "mobx";

import { ApplicationId, PaymentStatus } from "../adl-gen/krodok/snappy/db";
import { pathFromRoute, Route, routeFromPath } from "../routing/app-routes";
import { Router } from "../routing/router";

import { ApplicationStore } from "./application-store";
import { CheckoutStore } from "./checkout-store";
import { LoanSuiteApplicationStore } from "./loan-suite-application-form-store";
import { AppLinks } from "./shared/app-links";
import { TocStore } from "./toc-store";

/** Top level app state */
export type AppState =
  | {
      kind: "home";
      applicationStore: ApplicationStore;
    }
  | {
      kind: "loan-suite-application";
      loanSuiteApplicationStore: LoanSuiteApplicationStore;
    }
  | {
      kind: "review-and-confirm";
      tocStore: TocStore;
    }
  | {
      kind: "checkout";
      checkoutStore: CheckoutStore;
    }
  | {
      kind: "confirm";
      applicationStore: ApplicationStore;
    }
  | {
      kind: "terms-and-conditions";
    }
  | {
      kind: "privacy-policy";
    }
  | {
      kind: "learn-more";
    }
  | {
      kind: "about-us";
    };

/**
 * Top level app data and actions, including navigation.
 */
export class AppStore implements AppLinks {
  @observable.deep state: AppState;

  constructor(
    /** Router which is the bridge to the browser location */
    private readonly router: Router,
    /** The global application store */
    private readonly makeApplicationStore: (
      applicationId?: string
    ) => ApplicationStore,
    /** The Global loan suite application store */
    private readonly makeLoanSuiteApplicationStore: (
      applicationStore: ApplicationStore
    ) => LoanSuiteApplicationStore,
    /** Instantiates a terms and conditions store */
    private readonly makeTocStore: (
      applicationStore: ApplicationStore
    ) => TocStore,
    private readonly makeCheckoutStore: (
      applicationId: ApplicationId,
      secret?: string
    ) => CheckoutStore
  ) {
    this.state = {
      kind: "home",
      applicationStore: this.makeApplicationStore()
    };

    // Asynchronously register route change listener to not block construction,
    // in case the store factories rely on the app store being constructed
    setTimeout(() => router.registerRouteChangeListener(this.onRouteChange), 0);
  }

  /** Updates state based on route changes */
  @action onRouteChange = async () => {
    const route = routeFromPath(this.router.getCurrentPath());
    const applicationStore: ApplicationStore = this.makeApplicationStore();

    if (route === undefined) {
      this.state = { kind: "home", applicationStore };
      return;
    }

    if (route.route === "home") {
      this.state = { kind: "home", applicationStore };
      return;
    }

    if (route.route === "loan-suite-application") {
      await applicationStore.setApplication(route.applicationId);
      const application = applicationStore.getApplication();
      //Validate that the user has not already completed & paid for their application
      if (
        application &&
        application.payment.status === PaymentStatus.unprocessed
      ) {
        this.state = {
          kind: "loan-suite-application",
          loanSuiteApplicationStore: this.makeLoanSuiteApplicationStore(
            applicationStore
          )
        };
        return;
      } else {
        return this.router.go(this.getHomepageLink());
      }
    }

    if (route.route === "review-and-confirm") {
      await applicationStore.setApplication(route.applicationId);
      const application = applicationStore.getApplication();
      //Validate that the user has not already completed & paid for their application
      if (
        application &&
        application.applicationData.companyName.length > 0 &&
        application.payment.status === PaymentStatus.unprocessed
      ) {
        this.state = {
          kind: "review-and-confirm",
          tocStore: this.makeTocStore(applicationStore)
        };
        return;
      } else {
        return this.router.go(this.getHomepageLink());
      }
    }

    if (route.route === "checkout") {
      await applicationStore.setApplication(route.applicationId);
      const application = applicationStore.getApplication();
      //Validate that the TOC have been accepted, and that the application has not yet been paid for
      if (
        application &&
        application.acceptedTOS &&
        application.payment.status === PaymentStatus.unprocessed
      ) {
        this.state = {
          kind: "checkout",
          checkoutStore: this.makeCheckoutStore(
            route.applicationId,
            route.secret
          )
        };
        return;
      } else if (
        application &&
        application.acceptedTOS &&
        application.payment.status === PaymentStatus.processed
      ) {
        //If already paid for, forward the users to the confirmation page
        return this.router.go(
          this.getConfirmationLink() + "/" + route.applicationId
        );
      } else {
        return this.router.go(this.getHomepageLink());
      }
    }

    if (route.route === "confirm") {
      await applicationStore.setApplication(route.applicationId);
      const application = applicationStore.getApplication();
      //Validate that the TOC have been accepted and the application has been paid for
      if (
        application &&
        application.acceptedTOS &&
        application.payment.status === PaymentStatus.processed
      ) {
        this.state = {
          kind: "confirm",
          applicationStore
        };
        return;
      } else {
        return this.router.go(this.getHomepageLink());
      }
    }

    if (route.route === "terms-and-conditions") {
      this.state = {
        kind: "terms-and-conditions"
      };
      return;
    }

    if (route.route === "privacy-policy") {
      this.state = {
        kind: "privacy-policy"
      };
      return;
    }

    if (route.route === "learn-more") {
      this.state = {
        kind: "learn-more"
      };
      return;
    }

    if (route.route === "about-us") {
      this.state = {
        kind: "about-us"
      };
      return;
    }

    await this.navigateTo({ route: "home" });
  };

  getApplicationStore = (applicationId?: string): ApplicationStore => {
    return this.makeApplicationStore(applicationId);
  };

  async navigateTo(route: Route) {
    return this.router.go(pathFromRoute(route));
  }

  getHomepageLink(): string {
    return "/";
  }

  getTermsAndConditionsLink(): string {
    return "/terms-and-conditions";
  }

  getPrivacyPolicyLink(): string {
    return "/privacy-policy";
  }
  getLoanSuiteApplicationLink(): string {
    return "/loan-suite-application";
  }
  getReviewLink(): string {
    return "/review-and-confirm";
  }
  getCheckoutLink(): string {
    return "/checkout";
  }
  getConfirmationLink(): string {
    return "/confirm";
  }
  getErrorPageLink(): string {
    return "/error";
  }
  getLearnMoreLink(): string {
    return "/learn-more";
  }
  getAboutUsLink(): string {
    return "/about-us";
  }
}
