import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { AppLinks } from "../../../stores/shared/app-links";
import { GridContainer } from "../../common/grid-container/grid-container";
import { LogoFull } from "../../common/icon/icons";

const styles = require("./public-header.css");

// const logo = require("../../../assets/images/logo.png");

/** Props for the component */
export interface PublicHeaderProps {
  /** Additional classes to apply */
  className?: string;
  /** App links */
  appLinks: AppLinks;
}

/**
 * Header to show on the public site (not logged in).
 */
@observer
export class PublicHeader extends React.Component<PublicHeaderProps> {
  /** Renders the component */
  render() {
    return (
      <header className={classnames(styles.container, this.props.className)}>
        <GridContainer
          mdSpan={7}
          xsSpan={2}
          className={classnames(styles.homeIcon)}
        >
          <a href={this.props.appLinks.getHomepageLink()}>
            <LogoFull size="medium" />
          </a>
        </GridContainer>
        <GridContainer
          mdSpan={5}
          xsSpan={2}
          className={classnames(styles.terms)}
        >
          <div className={classnames(styles.headerNavigation)}>
            <a
              className={classnames(styles.headerItem)}
              href={this.props.appLinks.getAboutUsLink()}
            >
              <h3>About</h3>
            </a>
            <a
              className={classnames(styles.headerItem)}
              href={this.props.appLinks.getLearnMoreLink()}
            >
              <h3>How It Works & FAQs</h3>
            </a>
            <a
              className={classnames(styles.headerItem)}
              target={"_"}
              href={"https://matthew-kelly11522.medium.com/"}
            >
              <h3>Blog</h3>
            </a>
          </div>
        </GridContainer>
      </header>
    );
  }
}
