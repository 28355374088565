import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { CheckboxChecked, CheckboxUnChecked } from "../../common/icon/icons";

const styles = require("./check-item.css");

/** Props for the check item */
export interface CheckItemProps {
  /** Additional classes */
  className?: string;
  /** Label to show */
  label: string;
  /** Hint text for the question */
  hintText?: string;
  /** Whether it is selected */
  selected: boolean;
  /** Selection change handler */
  onChange?(value: boolean): void;
  /** Disabled */
  disabled?: boolean;
  /** Outlined */
  outlined?: boolean;
  /** in line link */
  inlineLink?: string;
  /** in line link text */
  inlineLinkText?: string;
}

/**
 * A Check box and  a label.
 */
@observer
export class CheckItem extends React.Component<CheckItemProps> {
  /** Renders the component */
  render() {
    return (
      <div
        className={classnames(
          styles.checkItem,
          this.props.outlined ? styles.outlined : undefined,
          this.props.className
        )}
        onClick={this.props.disabled ? undefined : this.onToggle}
      >
        {this.props.selected ? (
          <div className={this.props.disabled ? styles.disabled : undefined}>
            <CheckboxChecked />
          </div>
        ) : (
          <div className={this.props.disabled ? styles.disabled : undefined}>
            <CheckboxUnChecked />
          </div>
        )}
        <div className={styles.checkItemLabel}>
          <div>
            {this.props.label}
            {this.props.inlineLink && this.props.inlineLinkText && (
              <a
                className={classnames(styles.inlineLink)}
                href={this.props.inlineLink}
              >
                {this.props.inlineLinkText}
              </a>
            )}
          </div>
          {this.props.hintText && (
            <div className={styles.hintText}>{this.props.hintText}</div>
          )}
        </div>
      </div>
    );
  }

  /** Toggles the selection */
  onToggle = () => {
    if (this.props.onChange !== undefined && !this.props.disabled) {
      this.props.onChange(!this.props.selected);
    }
  };
}
