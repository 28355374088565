import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactModal from "react-modal";

import { assertNever } from "../../../util/types";
import { Cross } from "../../common/icon/icons";
import { Button, ButtonProps } from "../button/button";
import { CalendarPicker } from "../calendar-picker/calendar-picker";

const styles = require("./modal.css");

/** Props for the component */
export interface ModalCanvasProps {
  // None
}

interface ModalButton<T extends string> {
  /** Identifier of what button was clicked */
  id: T;
  /** Variant of button */
  variant: ButtonProps["variant"];
  /** Label on the button */
  label: ButtonProps["children"];
  /** Disabled callback for the button */
  disabled?: boolean;
}

/** Props to specify the content and behavior of a modal */
interface ModalInstance<T extends string> {
  /** Content to show in the modal, excluding buttons */
  content: JSX.Element;
  /** Content label to show to screen readers */
  contentLabel: string;
  /**
   * Function that returns the buttons to show
   * Note(mobx): Implemented as a function in order to be automatically observed by the render function
   */
  getButtons(): ModalButton<T>[];
  /**
   * Callback when the user clicks on a specified button.
   * NOTE: Clicking on any button will result in the closure of the modal.
   */
  onClickButton(buttonId?: T): void;
  /**
   * Callback when the user performs a non-button action to close the modal,
   * e.g. close icon or push escape.
   */
  onRequestClose(): void;
}

// Set root element for accessibility
// See: http://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

// Instantiate box around single global active modal instance
const store = observable.box<ModalInstance<string> | undefined>(undefined);

/** Shows a modal by setting it on the global store */
export function showModal<T extends string>(instance: ModalInstance<T>) {
  store.set(instance);
}

/**
 * Modal displayer to be mounted once in the app.
 */
@observer
export class ModalCanvas extends React.Component<ModalCanvasProps> {
  /** Renders the contents of a modal instance */
  renderModal<T extends string>(instance: ModalInstance<T>) {
    const Modal = observer(() => (
      <>
        <Cross
          className={styles.closeIcon}
          size="xsmall"
          onClick={() => this.onRequestClose(instance)}
        />
        <div className={styles.content}>{instance.content}</div>
        <div className={styles.buttons}>
          {instance.getButtons().map(button => (
            <Button
              key={button.id}
              className={styles.button}
              variant={button.variant}
              disabled={button.disabled}
              size="small"
              onClick={() => this.onClickButton(instance, button.id)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </>
    ));
    return <Modal />;
  }

  /** Renders the component */
  render() {
    const instance = store.get();
    return (
      <ReactModal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={!!instance}
        contentLabel={instance && instance.contentLabel}
        onRequestClose={instance && instance.onRequestClose}
        shouldCloseOnOverlayClick={false}
      >
        {instance ? this.renderModal(instance) : undefined}
      </ReactModal>
    );
  }

  /** Handles a button click */
  onClickButton = async <T extends string>(
    instance: ModalInstance<T>,
    buttonId: T
  ) => {
    instance.onClickButton(buttonId);
    store.set(undefined);
  };

  /** Handles a non-button request to close the dialog */
  onRequestClose = async <T extends string>(instance: ModalInstance<T>) => {
    instance.onRequestClose();
    store.set(undefined);
  };
}

/** Shows a calendar date picker to schedule a date for an action item */
export function showCalendarDatePicker(
  date: Date
): Promise<
  | { kind: "confirm"; value: { date: Date } }
  | { kind: "skip" }
  | { kind: "cancel" }
> {
  const dateObs = observable({ date });
  const onChangeDate = (value: Date) => {
    dateObs.date = value;
  };

  const Content = observer(() => (
    <div className={styles.center}>
      <p>
        <b>Select a date</b>
      </p>
      <CalendarPicker onChangeDate={onChangeDate} date={dateObs.date} />
    </div>
  ));

  return new Promise(resolve => {
    showModal({
      content: <Content />,
      contentLabel: "Date picker",
      getButtons: () => [
        { id: "confirm", variant: "primary", label: "CONFIRM" },
        { id: "skip", variant: "secondary", label: "SKIP" }
      ],
      onClickButton: (buttonId: "confirm" | "skip") => {
        if (buttonId === "confirm") {
          resolve({ kind: "confirm", value: dateObs });
        } else if (buttonId === "skip") {
          resolve({ kind: "skip" });
        } else {
          assertNever(buttonId);
        }
      },
      onRequestClose: () => resolve({ kind: "cancel" })
    });
  });
}
