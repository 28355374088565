import classNames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { Button } from "../button/button";

import { ExistingCompanySummary } from "./existing-company-summary";
import { SecuredLoanRegisterIntro } from "./secured-loan-register-intro";

const styles = require("./informational-question.css");

/** Question definition */
export interface InformationalQuestionProps {
  /** Additional classes to apply */
  className?: string;
  /** Text of the question */
  questionText: string;
  /** variant of sections, default is primary  */
  variant?: "hero" | "existing";
  /** Callback on click  */
  onClick(): Promise<void>;
  /** If the user has read and selected to continue */
  seen: boolean;
}

export class InformationalQuestionPropsImpl
  implements InformationalQuestionProps {
  @observable.ref
  seen: boolean;

  className?: string;
  questionText: string;
  variant?: "hero" | "existing";
  setter: (v: boolean) => void;

  constructor(v: {
    className?: string;
    questionText: string;
    variant?: "hero" | "existing";
    getter(): boolean;
    setter(v: boolean): void;
  }) {
    this.className = v.className;
    this.questionText = v.questionText;
    this.variant = v.variant;
    this.seen = v.getter();
    this.setter = v.setter;
  }

  // tslint:disable-next-line: no-async-without-await
  onClick = async (): Promise<void> => {
    this.setter(true);
  };
}

/**
 * A question that shows summary information
 */
@observer
export class InformationalQuestion extends React.Component<
  InformationalQuestionProps
> {
  /** Renders the question */
  render() {
    return (
      <div className={this.props.className}>
        <GridContainer
          xsSpan={4}
          smSpan={8}
          mdSpan={12}
          className={styles.primary}
        >
          {this.props.variant === "hero" ? (
            <h1 className={styles.hero}>{this.props.questionText}</h1>
          ) : (
            <h2 className={styles.primary}>{this.props.questionText}</h2>
          )}
        </GridContainer>
        <GridContainer xsSpan={4} smSpan={8} mdSpan={12}>
          {this.props.variant === "hero" && <SecuredLoanRegisterIntro />}
          {this.props.variant === "existing" && <ExistingCompanySummary />}
        </GridContainer>
        <GridContainer
          className={classNames(styles.ctaContainer)}
          xsSpan={4}
          smSpan={2}
          mdSpan={2}
        >
          <Button onClick={this.props.onClick}>Next</Button>
        </GridContainer>
      </div>
    );
  }
}
