import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { computed, observable } from "mobx";

import {
  CheckPaymentIntentResp,
  CreatePaymentIntentResp,
  UpdateApplicationResp
} from "../adl-gen/krodok/snappy/api";
import { ApplicationId } from "../adl-gen/krodok/snappy/db";
import { Maybe } from "../adl-gen/sys/types";
import { Service } from "../service/service";
import { CardSectionImpl } from "../ui/widgets/stripe-checkout/card-section";
import { CardElement } from "../ui/widgets/stripe-checkout/stripe-checkout";

import { AppRouter } from "./shared/app-router";

/**
 * Interface for stripe card element state
 */
export interface CardSectionValues {
  complete: boolean;
  error?: {
    type: "validation_error";
    code: string;
    message: string;
  };
  value?: string;
}

/**
 * Fields to manage for checkout
 */
export class CheckoutState {
  cardNumber: CardSectionValues;
  cardCvc: CardSectionValues;
  cardExpiry: CardSectionValues;
}

export class CheckoutStore {
  constructor(
    readonly applicationId: ApplicationId,
    readonly appRouter: AppRouter,
    //Payment secret passed from the url as an argument, checked server side.
    private readonly paymentSecret?: string,
    readonly service?: Service,
  ) {}

  @computed get cardSectionsSet() {
    return getCardSectionSet(this.checkoutState, this.clearResponse);
  }

  @observable.ref
  updateApplicationResponse: UpdateApplicationResp | undefined = undefined;

  @observable.ref
  createPaymentIntentResponse: CreatePaymentIntentResp | undefined = undefined;


  @observable.deep
  checkoutState: CheckoutState = {
    cardNumber: {
      complete: false,
      error: undefined,
      value: undefined
    },
    cardCvc: {
      complete: false,
      error: undefined,
      value: undefined
    },
    cardExpiry: {
      complete: false,
      error: undefined,
      value: undefined
    },
  };

  clearResponse = () => {
      this.createPaymentIntentResponse = undefined;
  }

  /**
   * Create a payment intent for the provided application id
   */
  createPaymentIntent = async (): Promise<CreatePaymentIntentResp | undefined> => {
    if(this.service) {
      const maybeSecret:Maybe<string> = this.paymentSecret ?  { kind: "just", value: this.paymentSecret } : {kind: "nothing"};
      const response = await this.service.createPaymentIntent({applicationId: this.applicationId, secret: maybeSecret});
      this.createPaymentIntentResponse = response;

      return response;
    }
    return undefined;
  };

  /**
   * Fetch the state of the payment id for the provided application id
   */
  checkPaymentIntent = async (): Promise<CheckPaymentIntentResp | undefined> => {
      if(this.service) {
          const response = await this.service.checkPaymentIntent(this.applicationId);
          return response;
      }
      return undefined
  }

  paymentFinished = async (): Promise<void> => {
      await this.appRouter.navigateToConfirmationLink(this.applicationId);
  }
}

const getCardSectionSet = (checkoutState: CheckoutState, clearResponse: () => void) => {
  const cardSections: CardElement[] = [
      getCardNumberSection(checkoutState, clearResponse),
      getCardCvcSection(checkoutState, clearResponse),
      getCardExpirySection(checkoutState, clearResponse)
    ];
  return cardSections;
};

const getCardNumberSection = (checkoutState: CheckoutState, clearResponse: () => void): CardElement => {
  return {
    kind: "number",
    value: new CardSectionImpl({
      cardType: "number",
      label: "Card number",
      setter: (v: StripeElementChangeEvent) => {
        checkoutState.cardNumber.complete = v.complete;
        checkoutState.cardNumber.error = v.error;
        clearResponse()
      },
      getError: () => checkoutState.cardNumber.error?.message,
      getComplete: () => checkoutState.cardNumber.complete
    })
  };
};

const getCardCvcSection = (checkoutState: CheckoutState, clearResponse: () => void): CardElement => {
  return {
    kind: "cvc",
    value: new CardSectionImpl({
      cardType: "cvc",
      label: "Card CVC",
      setter: (v: StripeElementChangeEvent) => {
        checkoutState.cardCvc.complete = v.complete;
        checkoutState.cardCvc.error = v.error;
        clearResponse();
      },
      getError: () => checkoutState.cardCvc.error?.message,
      getComplete: () => checkoutState.cardCvc.complete
    })
  };
};

const getCardExpirySection = (checkoutState: CheckoutState, clearResponse: () => void): CardElement => {
  return {
    kind: "expiry",
    value: new CardSectionImpl({
      cardType: "expiry",
      label: "Card Expiration",
      setter: (v: StripeElementChangeEvent) => {
        checkoutState.cardExpiry.complete = v.complete;
        checkoutState.cardExpiry.error = v.error;
        clearResponse();
      },
      getError: () => checkoutState.cardExpiry.error?.message,
      getComplete: () => checkoutState.cardExpiry.complete
    })
  };
};