import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

const styles = require("./full-width.css");

/** Props for the component */
export interface FullWidthProps {
  /** Style name to apply to root element */
  className?: string;
  /** Whether children are constrained to the grid */
  gridContent?: boolean;
}

/**
 * Container that spans the full width of the viewport.
 */
@observer
export class FullWidth extends React.Component<FullWidthProps> {
  /** Renders the container */
  render() {
    return (
      <div className={classnames(styles.container, this.props.className)}>
        <div className={this.props.gridContent && styles.gridContent}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
