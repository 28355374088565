import { computed, observable } from "mobx";
import moment from "moment";
import { InformationalQuestionPropsImpl } from "src/ui/widgets/informational-question/informational-question";

import { UpdateApplicationResp } from "../adl-gen/krodok/snappy/api";
import {
  CompanyType,
  LoanSuiteApplication,
  LoanType
} from "../adl-gen/krodok/snappy/db";
import { Service } from "../service/service";
import { MultiTextQuestionProps } from "../ui/widgets/multi-text-question/multi-text-question";
import { Question } from "../ui/widgets/questions-list/questions-list";
import { SelectQuestionPropsImpl } from "../ui/widgets/select-question/select-question";
import { TextQuestionImpl } from "../ui/widgets/text-question/text-question";

import { MultiTextInputImpl } from "./../ui/widgets/multi-text-question/multi-text-question";
import { ApplicationStore } from "./application-store";
import { AppRouter } from "./shared/app-router";

const DATE_FORMAT = "YYYY-MM-DD";

export class LoanSuiteApplicationStore {
  constructor(
    private readonly appRouter: AppRouter,
    readonly service: Service,
    private readonly applicationStore: ApplicationStore
  ) {
    this.loanSuiteApplication = this.applicationStore.getApplication().applicationData;
  }

  /** In progress loan suite form data */
  @computed get questionSet() {
    return assignQuestionsSet(this.getCompanyType, this.loanSuiteApplication, this.existingCompanySummary,this.securedLoanRegisterIntro, this.getResponse, this.clearResponse, this.getScrollToUnansweredQuestion)
  }

  @observable.ref
  response: UpdateApplicationResp | undefined = undefined;

  @observable.deep
  loanSuiteApplication: LoanSuiteApplication;

  @observable.deep
  existingCompanySummary: {
    seen: boolean
  } = {seen: false}

  @observable.deep
  securedLoanRegisterIntro: {
    seen: boolean
  } = {seen: false}

  /** Callback to submit the loan suite application form */
  onSubmit = async (): Promise<UpdateApplicationResp> => {
    if (this.applicationStore.applicationId === undefined) {
      throw new Error("Application Id not set");
    }
    const application = this.applicationStore.getApplication();
    if (!application) {
      throw new Error("No application found for id");
    }
    const response = await this.applicationStore.updateApplication({
      companyType: application.companyType,
      contactEmail: this.loanSuiteApplication.contactEmail,
      applicationData: this.loanSuiteApplication,
      acceptedTOS: application.acceptedTOS
    });

    this.response = response;
    if (response === UpdateApplicationResp.success) {
      await this.appRouter.navigateToReview(
        this.applicationStore.applicationId
      );
    }
    return response;
  };

  getResponse = (): UpdateApplicationResp | undefined => {
    return this.response;
  };

  clearResponse = () => {
    this.response = undefined;
  };

  getCompanyType = (): CompanyType | undefined => {
    return this.applicationStore.application?.companyType;
  }

  setScrollToUnansweredQuestion = (v: (() => void)): void => {
    this.scrollToUnansweredQuestion = v;
  }

  getScrollToUnansweredQuestion = (): void => {
    return this.scrollToUnansweredQuestion();
  }

  scrollToUnansweredQuestion: () => void;
}

const assignQuestionsSet = (
  getCompanyType: () => CompanyType | undefined,
  loanSuiteApplication: LoanSuiteApplication,
  existingCompanySummarySeen: {seen: boolean},
  securedLoanRegisterIntro: {seen: boolean},
  getResponse: () => UpdateApplicationResp | undefined,
  clearResponse: () => void,
  getScrollToUnansweredQuestion: () => void
) => {
  let questionsList: Question[] = [];
  //capita(HARRY) choose one method or the other, awful to read as multiple means of adding to an array
    questionsList.push(
      getSecuredLoanRegisterIntro(securedLoanRegisterIntro),
      getCompanyDetails(loanSuiteApplication, getResponse, clearResponse),
      getJurisdiction(loanSuiteApplication)
    );
    questionsList = questionsList.concat(
      getLendersQuestion(loanSuiteApplication, getScrollToUnansweredQuestion)
    );
    questionsList.push(getLoanDetails(loanSuiteApplication));
    questionsList = questionsList.concat(
      getDirectorsQuestion(loanSuiteApplication, getScrollToUnansweredQuestion)
    );
    questionsList.push(
      getDirectorMeetingDetails(loanSuiteApplication),
      getContactEmail(loanSuiteApplication, getResponse, clearResponse)
    );
    if (
      getCompanyType() === CompanyType.existing
    ) {
      questionsList.push(getLoanType(loanSuiteApplication));
      if (
        loanSuiteApplication.loanType.kind === "just" &&
        loanSuiteApplication.loanType.value === LoanType.existing
      ) {
        questionsList.push(getExistingLoanSummary(existingCompanySummarySeen));
        questionsList.push(getInitialAdvanceDetails(loanSuiteApplication));
      }
    }
    return questionsList
};

// tslint:disable-next-line: no-async-without-await
const onAddAdditionalLender = async (
  loanSuiteApplication: LoanSuiteApplication
) => {
  loanSuiteApplication.lenders.push({
    name: "",
    address: ""
  });
};

// tslint:disable-next-line: no-async-without-await
const onAddAdditionalDirector = async (
  loanSuiteApplication: LoanSuiteApplication
) => {
  loanSuiteApplication.directors.push({
    name: ""
  });
};

const getSecuredLoanRegisterIntro = (
  securedLoanRegisterIntro: {seen: boolean}
): Question => {
  return {
    kind: "informational",
    value: new InformationalQuestionPropsImpl({
      questionText: "Secured loan registration",
      variant: "hero",
      getter: () => securedLoanRegisterIntro.seen,
      setter: (v: boolean) => {
        securedLoanRegisterIntro.seen = v;
      }
    })
  }
}

const getCompanyDetails = (
  loanSuiteApplication: LoanSuiteApplication,
  getResponse: () => UpdateApplicationResp | undefined,
  clearResponse: () => void
): Question => {
  return {
    kind: "multi-text",
    value: {
      props: {
        questionText: "What are the details of the borrower company?",
        hintText: "Input the full address including state and postcode.",
        inputs: [
          new MultiTextInputImpl({
            placeholderText: "Company name",
            name: "companyName",
            getter: () => loanSuiteApplication.companyName,
            setter: (v: string) => {
              loanSuiteApplication.companyName = v;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Company ACN",
            name: "companyAcn",
            inputType: "number",
            maxLength: 9,
            getter: () => loanSuiteApplication.companyAcn,
            setter: (v: string) => {
              loanSuiteApplication.companyAcn = v;
              clearResponse();
            },
            getError: () => {
              return getResponse() === UpdateApplicationResp.acnInvalidLength
                ? "ACN must be 9 digits in length"
                : undefined;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Registered address",
            name: "companyRegisteredAddress",
            getter: () => loanSuiteApplication.companyRegisteredAddress,
            setter: (v: string) => {
              loanSuiteApplication.companyRegisteredAddress = v;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Company Email",
            name: "companyEmail",
            getter: () => loanSuiteApplication.companyEmail,
            setter: (v: string) => {
              loanSuiteApplication.companyEmail = v;
              clearResponse();
            },
            getError: () => {
              return getResponse() ===
                UpdateApplicationResp.companyEmailInvalidFormat
                ? "Invalid email format"
                : undefined;
            }
          })
        ]
      }
    }
  };
};

const getJurisdiction = (
  loanSuiteApplication: LoanSuiteApplication
): Question => {
  return {
    kind: "select",
    value: new SelectQuestionPropsImpl<string>({
      questionText: "In what Australian state will the agreement be entered?",
      options: [
        {
          label: "New South Wales",
          value: "nsw"
        },
        {
          label: "Queensland",
          value: "qld"
        },
        {
          label: "Victoria",
          value: "vic"
        },
        {
          label: "Western Australia",
          value: "wa"
        },
        {
          label: "Northern Territory",
          value: "nt"
        },
        {
          label: "South Australia",
          value: "sa"
        },
        {
          label: "Tasmania",
          value: "tas"
        },
        {
          label: "Australian capital Territory",
          value: "act"
        }
      ],
      getter: () => loanSuiteApplication.jurisdiction,
      setter: (v: string) => {
        loanSuiteApplication.jurisdiction = v;
      }
    })
  };
};

const getLendersQuestion = (
  loanSuiteApplication: LoanSuiteApplication,
  getScrollToUnansweredQuestion: () => void
): Question[] => {
  const questions: Question[] = [];
  loanSuiteApplication.lenders.forEach((lender, idx) => {
    const props: MultiTextQuestionProps = {
      questionText: `What are the details of the lender(s)`,
      hintText: "A lender can be either be an individual or a company. If a company, add 9-digit ACN after the name.",
      inputs: [
        new MultiTextInputImpl({
          placeholderText: `Name`,
          name: `lender_name_${idx + 1}`,
          getter: () => lender.name,
          setter: (v: string) => {
            lender.name = v;
          }
        }),
        new MultiTextInputImpl({
          placeholderText: "Address",
          name: `lender_address_${idx + 1}`,
          getter: () => lender.address,
          setter: (v: string) => {
            lender.address = v;
          }
        })
      ]
    };
    questions.push({
      kind: "multi-text",
      value: {
        //capita(HARRY) add icons to props
        secondaryButtonProps: {
          variant: "secondary",
          children: "ADD ANOTHER LENDER",
          onClick: async () => {
            await onAddAdditionalLender(loanSuiteApplication);
            getScrollToUnansweredQuestion();
          }
        },
        props
      }
    });
  });
  return questions;
};

const getDirectorsQuestion = (
  loanSuiteApplication: LoanSuiteApplication,
  getScrollToUnansweredQuestion: () => void
): Question[] => {
  const questions: Question[] = [];
  loanSuiteApplication.directors.forEach((director, idx) => {
    const props: MultiTextQuestionProps = {
      questionText: `What are the name(s) of the borrower company director(s)?`,
      inputs: [
        new MultiTextInputImpl({
          placeholderText: "Name",
          name: `director_name_${idx}`,
          getter: () => director.name,
          setter: (v: string) => {
            director.name = v;
          }
        })
      ]
    };
    questions.push({
      kind: "multi-text",
      value: {
        secondaryButtonProps: {
          variant: "secondary",
          children: "ADD ANOTHER DIRECTOR",
          onClick: async () => {
            await onAddAdditionalDirector(loanSuiteApplication);
            getScrollToUnansweredQuestion();
          }
        },
        props
      }
    });
  });
  return questions;
};

const getLoanDetails = (
  loanSuiteApplication: LoanSuiteApplication
): Question => {
  return {
    kind: "multi-text",
    value: {
      props: {
        questionText: "What are the details of the loan/advance",
        inputs: [
          new MultiTextInputImpl({
            placeholderText: "Amount",
            name: "loanAmount",
            inputType: "number",
            getter: () => loanSuiteApplication.loanAmount,
            setter: (v: string) => {
              loanSuiteApplication.loanAmount = v;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Interest rate (e.g. 5%)",
            name: "loanInterestRate",
            inputType: "number",
            getter: () => loanSuiteApplication.loanInterestRate,
            setter: (v: string) => {
              loanSuiteApplication.loanInterestRate = v;
            }
          })
        ]
      }
    }
  };
};

const getDirectorMeetingDetails = (
  loanSuiteApplication: LoanSuiteApplication
): Question => {
  return {
    kind: "multi-text",
    value: {
      props: {
        questionText: "What are the details of the Director meeting?",
        hintText:
          "The purpose of this meeting is to consider entering into the loan agreement and the security agreement. The Chairman of the meeting can be any current director of the borrower company.",
        inputs: [
          new MultiTextInputImpl({
            placeholderText: "Date",
            name: "meetingDate",
            inputType: "date",
            getter: () => loanSuiteApplication.meetingDate,
            setter: (v: string) => {
              loanSuiteApplication.meetingDate = moment(v).format(DATE_FORMAT);
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Time (HH:MM e.g. 12:20pm)",
            name: "meetingTime",
            getter: () => loanSuiteApplication.meetingTime,
            setter: (v: string) => {
              loanSuiteApplication.meetingTime = v;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Suburb",
            name: "meetingSuburb",
            getter: () => loanSuiteApplication.meetingSuburb,
            setter: (v: string) => {
              loanSuiteApplication.meetingSuburb = v;
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Chairman",
            name: "meetingChairman",
            getter: () => loanSuiteApplication.meetingChairman,
            setter: (v: string) => {
              loanSuiteApplication.meetingChairman = v;
            }
          })
        ]
      }
    }
  };
};

const getContactEmail = (
  loanSuiteApplication: LoanSuiteApplication,
  getResponse: () => UpdateApplicationResp | undefined,
  clearResponse: () => void
): Question => {
  return {
    kind: "text",
    value: new TextQuestionImpl({
      questionText: "What is the primary contact email for registration?",
      placeholderText: "Email",
      hintText: "This is the email of the party filling out the application on behalf of the lender, including a professional adviser",
      name: "contactEmail",
      getter: () => loanSuiteApplication.contactEmail,
      setter: (v: string) => {
        loanSuiteApplication.contactEmail = v;
        clearResponse();
      },
      getError: () => {
        return getResponse() === UpdateApplicationResp.contactEmailInvalidFormat
          ? "Invalid email format"
          : undefined;
      }
    })
  };
};

const getLoanType = (loanSuiteApplication: LoanSuiteApplication): Question => {
  return {
    kind: "select",
    value: new SelectQuestionPropsImpl<LoanType>({
      questionText: "Are you registering a new loan or an existing loan?",
      options: [
        {
          label: "New Loan",
          value: LoanType.new
        },
        {
          label: "Existing Loan",
          value: LoanType.existing
        }
      ],
      getter: () =>
        loanSuiteApplication.loanType.kind === "just"
          ? loanSuiteApplication.loanType.value
          : undefined,
      setter: (v: LoanType) => {
        loanSuiteApplication.loanType = {
          kind: "just",
          value: v
        };
      }
    })
  };
};

const getExistingLoanSummary = (
  existingCompanySummary: {seen: boolean}
): Question => {
  return {
    kind: "informational",
    value: new InformationalQuestionPropsImpl({
      questionText: "Existing loan documents and consideration",
      variant: "existing",
      getter: () => existingCompanySummary.seen,
      setter: (v: boolean) => {
        existingCompanySummary.seen = v;
      }
    })
  }
}

const getInitialAdvanceDetails = (
  loanSuiteApplication: LoanSuiteApplication
): Question => {
  return {
    kind: "multi-text",
    value: {
      props: {
        questionText: "What are the details of the inital advance?",
        inputs: [
          new MultiTextInputImpl({
            placeholderText: "Initial loan date",
            name: "initialLoanDate",
            hintText: "If no amount has been repaid enter 0",
            inputType: "date",
            getter: () => {
              return loanSuiteApplication.initialLoanDate.kind === "just"
                ? loanSuiteApplication.initialLoanDate.value
                : "";
            },
            setter: (v: string) => {
              loanSuiteApplication.initialLoanDate = {
                kind: "just",
                value: moment(v).format(DATE_FORMAT)
              };
            }
          }),
          new MultiTextInputImpl({
            placeholderText: "Amount repaid",
            name: "amountRepaid",
            inputType: "number",
            getter: () => {
              return loanSuiteApplication.loanAmountRepaid.kind === "just"
                ? loanSuiteApplication.loanAmountRepaid.value
                : "";
            },
            setter: (v: string) => {
              loanSuiteApplication.loanAmountRepaid = {
                kind: "just",
                value: v
              };
            }
          })
        ]
      }
    }
  };
};

