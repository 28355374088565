import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { AppLinks } from "../../../stores/shared/app-links";
import { GridContainer } from "../../common/grid-container/grid-container";
import { LogoFull } from "../../common/icon/icons";

const styles = require("./public-footer.css");

/** Props for the component */
export interface PublicFooterProps {
  /** Additional classes to apply */
  className?: string;
  /** App links */
  appLinks: AppLinks;
}

/**
 * Footer to show on the public site (not logged in).
 */
@observer
export class PublicFooter extends React.Component<PublicFooterProps> {
  /** Renders the component */
  render() {
    return (
      <footer className={classNames(styles.container, this.props.className)}>
        <div className={styles.footerLinks}>
          <GridContainer
            className={classNames(styles.section, styles.shareIcons)}
            xsSpan={4}
            mdSpan={5}
          >
            <a
              href={this.props.appLinks.getHomepageLink()}
              className={styles.icon}
            >
              <LogoFull size="medium" />
            </a>
          </GridContainer>
          <GridContainer
            className={classNames(styles.section, styles.termsAndConditions)}
            xsSpan={4}
            mdSpan={5}
          >
            <div className={classNames(styles.footerNavigation)}>
              <a href={this.props.appLinks.getTermsAndConditionsLink()}>
                <h3>Terms & Conditions</h3>
              </a>
              <a href={this.props.appLinks.getPrivacyPolicyLink()}>
                <h3>Privacy Policy</h3>
              </a>
            </div>
          </GridContainer>
          <GridContainer
            className={classNames(styles.section, styles.termsAndConditions)}
            xsSpan={4}
            mdSpan={2}
          >
            <div className={classNames(styles.copyright)}>2020 Krodok</div>
          </GridContainer>
        </div>
      </footer>
    );
  }
}
