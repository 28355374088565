import {
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  Stripe,
  StripeElements
} from "@stripe/stripe-js";
import classNames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import {
  CheckPaymentIntentResp, CreatePaymentIntentResp
} from "../../../adl-gen/krodok/snappy/api";
import { GridContainer } from "../../common/grid-container/grid-container";
import { Lock } from "../../common/icon/icons";
import { Button } from "../button/button";

import { CardSection, CardSectionProps } from "./card-section";
const styles = require("./stripe-checkout.css");

export interface CardElement {
  kind: "number" | "cvc" | "expiry" | "postcode";
  value: CardSectionProps;
}

export interface StripeCheckoutProps {
  getClientSecret(): Promise<CreatePaymentIntentResp | undefined>;
  checkPayment(): Promise<CheckPaymentIntentResp | undefined>;
  paymentFinished(): Promise<void>;
  stripe: Stripe | null;
  elements: StripeElements | null;
  cardElements: CardElement[];
}

@observer
export class StripeCheckout extends React.Component<StripeCheckoutProps> {
  @observable stripeError: string | undefined;
  @observable processingPayment: boolean;
  //Initialise Stripe with public key
  submit = async () => {
    this.stripeError = undefined;
    this.processingPayment = true;
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not loaded
      return;
    }

    const createPaymentIntentResp = await this.props.getClientSecret();
    if (
      createPaymentIntentResp &&
      createPaymentIntentResp.kind === "stripeError"
    ) {
      this.stripeError = createPaymentIntentResp.value.message;
      this.processingPayment = false;
      return;
    } else if (
      createPaymentIntentResp &&
      createPaymentIntentResp.kind === "clientSecret"
    ) {
      const cardNumberElement = elements.getElement("cardNumber");
      if (cardNumberElement) {
        const confirmPaymentResult = await stripe.confirmCardPayment(
          createPaymentIntentResp.value,
          {
            payment_method: {
              card: cardNumberElement,
            }
          }
        );

        if (confirmPaymentResult.error) {
            this.stripeError = confirmPaymentResult.error.message
            this.processingPayment = false;
        } else {
            const status = await this.props.checkPayment();
            if (status && status.kind === "success") {
                await this.props.paymentFinished();
            } else if (status?.kind === "errorMessage") {
                this.stripeError === status.value
            }
        }
      }
    }
  };

  render() {
    const validFields = this.props.cardElements.every(
      value => value.value.complete && !value.value.errorMessage
    );
    return (
      <div className={classNames(styles.checkout)}>
        <GridContainer mdSpan={12} xsSpan={4}>
        {this.stripeError && (
            <div className={classNames(styles.errorAlert)}>
              {this.stripeError}
            </div>
        )}
        </GridContainer>
        <GridContainer mdSpan={4} smSpan={4} xsSpan={4}>
        {this.props.cardElements.map((cardElement, index) => {
          return (
            <div key={index}>
              <CardSection {...cardElement.value} />
            </div>
          );
        })}
        <div className={classNames(styles.container)}>
          <Button
            onClick={this.submit}
            Icon={Lock}
            iconPosition={"left"}
            disabled={!this.props.stripe || !validFields || this.processingPayment}
          >
            PURCHASE
          </Button>
        </div>
        </GridContainer>
      </div>
    );
  }
}

export interface StripeCheckoutFormProps {
  stripePublicKey: string;
  getClientSecret(): Promise<CreatePaymentIntentResp | undefined>;
  cardElements: CardElement[];
  checkPayment(): Promise<CheckPaymentIntentResp | undefined>;
  paymentFinished(): Promise<void>
}

@observer
export class StripeCheckoutForm extends React.Component<
  StripeCheckoutFormProps
> {
  render() {
    return (
      <Elements stripe={loadStripe(this.props.stripePublicKey)}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <StripeCheckout
              stripe={stripe}
              elements={elements}
              getClientSecret={this.props.getClientSecret}
              checkPayment={this.props.checkPayment}
              paymentFinished={this.props.paymentFinished}
              cardElements={this.props.cardElements}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}
