import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";

const styles = require("./page-body.css");

/** Whether to always show debug overlay - useful for global debugging */
const ALWAYS_DEBUG = false;

/** Props for the component */
export interface PageBodyProps {
  /** Style name to apply to root element */
  className?: string;
  /** Additional classes to apply to the content container (e.g. make it white to lift it above a background) */
  contentClassName?: string;
  /** Elements of the primary content area */
  children?: React.ReactNode;
  /** Whether to render the responsive grid layout overlay */
  debug?: boolean;
}

/**
 * Body section that goes inside various page layouts.
 */
@observer
export class PageBody extends React.Component<PageBodyProps> {
  /** Renders the responsive grid layout overlay */
  renderGridOverlay() {
    return (
      <div>
        <GridContainer debug xsSpan={1} smSpan={1} mdSpan={1} />
        <GridContainer debug xsSpan={1} smSpan={1} mdSpan={1} />
        <GridContainer debug xsSpan={1} smSpan={1} mdSpan={1} />
        <GridContainer debug xsSpan={1} smSpan={1} mdSpan={1} />
        <GridContainer debug smSpan={1} mdSpan={1} />
        <GridContainer debug smSpan={1} mdSpan={1} />
        <GridContainer debug smSpan={1} mdSpan={1} />
        <GridContainer debug smSpan={1} mdSpan={1} />
        <GridContainer debug mdSpan={1} />
        <GridContainer debug mdSpan={1} />
        <GridContainer debug mdSpan={1} />
        <GridContainer debug mdSpan={1} />
      </div>
    );
  }

  /** Renders the page */
  render() {
    return (
      <div className={classnames(styles.container, this.props.className)}>
        <div
          className={classnames(styles.content, this.props.contentClassName)}
        >
          {this.props.debug || ALWAYS_DEBUG
            ? this.renderGridOverlay()
            : undefined}
          {this.props.children}
        </div>
      </div>
    );
  }
}
