import classnames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";

import { assertNever } from "../../../util/types";
import { ChevronDown, Cross } from "../../common/icon/icons";
import { showCalendarDatePicker } from "../modal/modal";

const styles = require("./text-input.css");

/** Line height of the text area based on common fonts styles */
const fonts = require("../../common/fonts/fonts.css");
const lineHeight =
  parseInt(fonts.defaultFontSize, 10) * parseFloat(fonts.defaultLineHeight);

type TextInputType =
  | "text"
  | "password"
  | "number"
  | "tel"
  | "email"
  | "date"
  | "datetime-local";

export interface TextAutogrowType {
  kind: "text-autogrow";
  value?: { initialRows?: number; maxRows?: number };
}

/** Props for the component */
export interface TextInputProps {
  /** Additional classes on the container */
  className?: string;
  /** HTML input type */
  type: TextInputType | TextAutogrowType;
  /** Text value */
  value: string;
  /** Error message */
  errorMessage?: string;
  /** Change handler */
  onChange?(value: string): void;
  /** Placeholder label */
  placeholder: string;
  /** Disabled */
  disabled?: boolean;
  /** Auto Complete (defaults to off) */
  autoComplete?: "on" | "off" | "new-password";
  /** Name of the input */
  name?: string;
  /** Minium length of the input */
  minLength?: number;
  /** Max length of the input */
  maxLength?: number;
}

/**
 * Component for entering text.
 */
@observer
export class TextInput extends React.Component<TextInputProps> {
  /** Number of rows for the textarea */
  @observable.deep rows: number =
    this.isTextAutogrowType(this.props.type) && this.props.type.value
      ? this.props.type.value.initialRows || 1
      : 1;

  /** Ref to the textarea */
  textareaRef: Element | null;

  componentDidMount() {
    this.adjustAutogrowHeight(this.textareaRef);
  }

  /** Check if the type is a text autogrow type */
  isTextAutogrowType(_x: TextInputProps["type"]): _x is TextAutogrowType {
    return (
      typeof this.props.type === "object" &&
      this.props.type.kind === "text-autogrow"
    );
  }

  renderInput(
    variant:
      | {
          kind: "input";
          value: React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
          >;
        }
      | {
          kind: "textautogrow";
          value: React.DetailedHTMLProps<
            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            HTMLTextAreaElement
          >;
        }
  ) {
    const commonProps = {
      className: classnames(
        styles.input,
        this.props.value.length > 0 && styles.nonEmtpy
      ),
      value: this.props.value,
      name: this.props.name,
      minLength: this.props.minLength,
      maxLength: this.props.maxLength,
      autoComplete: this.props.autoComplete ? this.props.autoComplete : "off",
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
      onChange: this.onChange
    };
    if (variant.kind === "input") {
      if (variant.value.type === "date") {
        const date = moment(this.props.value, "YYYY-MM-DD");
        if (date.isValid()) {
          commonProps.value = date.format("DD/MM/YYYY");
        }
        return (
          <div className={styles.dateContainer}>
            <ChevronDown
              className={styles.icon}
              size="xsmall"
              onClick={this.props.disabled ? undefined : this.onDatePicker}
            />
            <input {...commonProps} disabled />
          </div>
        );
      } else {
        return <input {...commonProps} {...variant.value} />;
      }
    } else if (variant.kind === "textautogrow") {
      return <textarea {...commonProps} {...variant.value} />;
    }
    return assertNever(variant, "Unknown input type");
  }

  /** Renders the component */
  render() {
    return (
      <div
        className={classnames(
          styles.container,
          this.props.errorMessage && styles.error,
          this.props.value && styles.filled,
          this.props.disabled && styles.disabled,
          this.props.className
        )}
      >
        {this.isTextAutogrowType(this.props.type)
          ? this.renderInput({
              kind: "textautogrow",
              value: {
                ref: this.onTextAreaRef,
                rows: this.rows
              }
            })
          : this.renderInput({
              kind: "input",
              value: {
                type: this.props.type
              }
            })}
        {this.props.value ? (
          <div className={styles.smallLabel}>{this.props.placeholder}</div>
        ) : (
          undefined
        )}
        {this.props.errorMessage ? (
          <>
            <div className={styles.errorMessage}>{this.props.errorMessage}</div>
            <Cross className={styles.errorIcon} size="xsmall" color="orange" />
          </>
        ) : (
          undefined
        )}
      </div>
    );
  }

  /** Adjust the textarea autogrow height */
  adjustAutogrowHeight(element: Element | null) {
    if (element) {
      if (this.isTextAutogrowType(this.props.type)) {
        this.rows = Math.floor(element.scrollHeight / lineHeight) - 1;
        if (
          this.props.type.value &&
          this.props.type.value.maxRows &&
          this.rows > this.props.type.value.maxRows
        ) {
          this.rows = this.props.type.value.maxRows;
        }
      }
    }
  }

  /** Callback when the text changes on inputs */
  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!this.props.disabled && this.props.onChange) {
      if (this.isTextAutogrowType(this.props.type)) {
        this.adjustAutogrowHeight(e.target);
      }
      //(HACK: HARRY) -1 applied as target length is one step behind rendered input
      if (e.target.value.length - 1 === this.props.maxLength) {
        return;
      }
      this.props.onChange(e.target.value);
    }
  };

  /** Callback to receive the textarea ref */
  onTextAreaRef = (ref: Element | null) => {
    this.textareaRef = ref;
  };

  /** Callback to show a date picker */
  onDatePicker = async () => {
    const m = moment(this.props.value);
    let date = moment().toDate();
    if (m.isValid()) {
      date = m.toDate();
    }

    const response = await showCalendarDatePicker(date);

    if (response.kind === "confirm" && this.props.onChange) {
      this.props.onChange(response.value.date.toISOString());
    }
  };
}
