import { observer } from "mobx-react";
import * as React from "react";

import { Application, LoanType } from "../../../adl-gen/krodok/snappy/db";
import { AppLinks } from "../../../stores/shared/app-links";
import { GridContainer } from "../../common/grid-container/grid-container";
import { ArrowRight, CircleTick, Download } from "../../common/icon/icons";
import { Button } from "../../widgets/button/button";

const styles = require("./confirm-page.css");

/** Props for the component */
export interface ConfirmProps {
  appLinks: AppLinks;
  application: Application;
}

/**
 * The Confirm page.
 */
@observer
export class ConfirmPage extends React.Component<ConfirmProps> {
  /** Renders the component */
  render() {
    return (
      <div className="container">
        <div className={styles.title}>
          <CircleTick color="green" size="small-medium" />
          <h1>Payment confirmed</h1>
        </div>
        <GridContainer xsSpan={4} mdSpan={12} className={styles.documents}>
          <p>
            Your payment has been received and your documents are ready to
            download. Your document pack includes:
          </p>
          {this.props.application.applicationData.loanType.kind === "just" &&
          this.props.application.applicationData.loanType.value ===
            LoanType.existing ? (
            <ul>
              <li>Letter of demand</li>
              <li>Company Response to letter of demand</li>
              <li>Deed of forbearance</li>
              <li>Minutes of meeting</li>
              <li>Loan Deed</li>
              <li>General Security Agreement</li>
            </ul>
          ) : (
            <ul>
              <li>Minutes of meeting</li>
              <li>Loan Deed</li>
              <li>General Security Agreement</li>
            </ul>
          )}
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={12} className={styles.nextSteps}>
          <p>
            The documents must be signed by all of the relevant parties in wet
            ink (with a pen) so that they can be relied upon and enforced.
            Krodok will register the security interest on the Personal Property
            Securities Register (PPSR) in approximately 4 business days, as the
            agent of the Lender, on the assumption that all of the documents
            have been signed by all of the relevant parties, unless advised in
            writing to the contrary.
          </p>
          <p>Download your documents below</p>
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={4}>
          <Button
            className={styles.download}
            Icon={Download}
            iconPosition={"right"}
            href={this.props.application.documentUrl}
          >
            Download
          </Button>
          <Button
            className={styles.return}
            Icon={ArrowRight}
            iconPosition={"right"}
            variant={"tertiary"}
            href={this.props.appLinks.getHomepageLink()}
          >
            Return home
          </Button>
        </GridContainer>
      </div>
    );
  }
}
