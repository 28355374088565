import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";

const styles = require("./how-does-this-work-page.css");

/**
 * The How Does This Work page.
 */
@observer
export class HowDoesThisWorkPage extends React.Component {
  renderWhatSection() {
    return (
      <GridContainer
        xsSpan={4}
        smSpan={8}
        mdSpan={12}
        className={classNames(styles.section)}
      >
        <h2>
          What does{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong> do?
        </h2>
        <p>
          <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
          protects you.
        </p>
        <p>
          It bypasses expensive legal work and secures the protections that are
          built into the legal system for you that you may not know about – in
          effect,{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong>:
        </p>
        <ul>
          <li>secures your investment against your company’s assets</li>
          <li>
            raises you to ‘secured creditor’ status, like a bank, so that if
            your company becomes financially distressed, you will get paid back
            your debt before anyone else
          </li>
        </ul>
      </GridContainer>
    );
  }

  renderHowSection() {
    return (
      <GridContainer
        xsSpan={4}
        smSpan={8}
        mdSpan={12}
        className={classNames(styles.section)}
      >
        <h2>
          How does{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong> work?
        </h2>
        <p>
          <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
          empowers you.
        </p>
        <p>
          By answering a handful of short and simple questions online,{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
          adapts to your own circumstances and generates a bundle of documents
          that:
        </p>
        <ul>
          <li>
            creates a loan between you and your company for any investment,
            including future and past investments
          </li>
          <li>
            creates a promise between you and your company so that the company
            will repay your investment and if it can’t, it will let you
            personally possess and sell its assets to reduce that debt
          </li>
          <li>
            registers your interest on the Personal Property Securities
            Register, which is the most important step in being protected
          </li>
        </ul>
      </GridContainer>
    );
  }

  renderWhySection() {
    return (
      <GridContainer
        xsSpan={4}
        smSpan={8}
        mdSpan={12}
        className={classNames(styles.section)}
      >
        <h2>
          Why do you need{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong>?
        </h2>
        <p>
          <strong className={classNames(styles.highlight)}>krodok</strong> is
          your survival.
        </p>
        <p>
          Because{" "}
          <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
          raises you to a secured creditor status, you have more rights and
          powers even if your company becomes financially distressed - you’ll
          have:
        </p>
        <ul>
          <li>
            greater control over the company’s future including the ability to
            reorganise the company’s debts or negotiate a way for your company
            to trade out of a poor position and prosper
          </li>
          <li>
            the ability, if your company can’t recover, to get paid back your
            investment
          </li>
          <li>
            registers your interest on the Personal Property Securities
            Register, which is the most important step in being protected
          </li>
        </ul>
      </GridContainer>
    );
  }

  renderFAQSection() {
    return (
      <GridContainer
        className={classNames(styles.section)}
        mdSpan={12}
        smSpan={8}
        xsSpan={4}
      >
        <h1>Frequently Asked Questions</h1>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              What is{" "}
              <strong className={classNames(styles.highlight)}>krodok</strong>?
            </strong>
          </p>
          <p>
            <strong className={classNames(styles.highlight)}>krodok</strong> is
            a digital tool designed for you, as a small-medium business owner,
            to protect the investment they make in your company. It
            automatically generates legally-enforceable documents that raises
            you to being a first-ranking secured creditor of your business,
            registered on the PPSR (see below), without the fuss or expense of
            dealing with lawyers.
          </p>
          <p>
            We started{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
            after being insolvency lawyers for nearly 20 years. We constantly
            saw companies’ owners encounter sudden liquidity problems and lose
            their business and their investment because they were not properly
            protected.
          </p>
          <p>
            The reason that owners who invest in their own companies have not
            been properly protected is because not enough is understood about
            the insolvency regime and the tools available to rehabilitate a
            business and because the protection has been too expensive to obtain
            from lawyers.
          </p>
          <p>
            Owners often start with a relatively small personal investment in
            their new business. The cost to properly protect that investment has
            often been disproportionately high to the initial investment. The
            issue is that owners often end up putting in significantly more of
            their own money into their business over the years, but never take
            the step to protect their investment. Once{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
            documents are put in place they protect all investments made by the
            owners.
          </p>
          <p>
            <strong className={classNames(styles.highlight)}>krodok</strong> has
            been designed to <strong>protect</strong> you,{" "}
            <strong>empower</strong> you with options to face headwinds and
            ultimately gives you and your business the best chance to{" "}
            <strong>survive.</strong>
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>What documents will I get?</strong>
          </p>
          <p>You will get the following documents:</p>
          <ul>
            <li>minutes of meeting resolving to enter loan</li>
            <li>loan deed</li>
            <li>general security agreement</li>
          </ul>
          <p>
            If the loan has been made over a number of years since incorporation
            of the company (see below), in addition to the documents referred to
            above for a new loan, you will get the following documents:
          </p>
          <ul>
            <li>
              letter of demand from owners to company for repayment of loan
            </li>
            <li>
              letter from company responding to letter of demand proposing
              forbearance
            </li>
            <li>forbearance deed (see below)</li>
          </ul>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              Why do I need{" "}
              <strong className={classNames(styles.highlight)}>krodok</strong>?
            </strong>
          </p>
          <p>
            You need{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong> as
            a shield to protect your business and your future. You need{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong> if
            you want to weather financial headwinds that occur in circumstances
            such as:
          </p>
          <ul>
            <li>
              injury, illness or retirement of a key member of your business
            </li>
            <li>
              sudden issues for your suppliers that significantly delay supply
            </li>
            <li>
              sudden financial issues for your customers, including bankruptcy
              or insolvency, so they cannot pay
            </li>
            <li>
              sudden world events such as pandemic, natural disaster or war.
            </li>
          </ul>
          <p>
            You need{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong> to
            put you in the best position in the event of insolvency. It is
            extremely important that in the event of insolvency, you are in a
            position to restructure your company’s debt under a deed of company
            arrangement, or if that is not possible, you are in a position to
            recover some or all of your investment.
          </p>
          <p>
            The bank controls the insolvency process because it is properly
            protected with a registered secured loan.{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
            puts the owner in the position of the bank.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>How do I get the documents?</strong>
          </p>
          <p>
            Answer between 14 and 16 simple questions that are prompted by the
            website. Whether you will answer 14 or 16 questions depends on
            whether you are protecting a new loan or protecting amounts loaned
            by you to the company over a number of years. Once you have entered
            the information requested and entered your credit or debit card
            details, you will be able to download the suite of documents
            directly to computer.
          </p>
        </div>

        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>How much does it cost?</strong>
          </p>
          <p>
            The one-off, all-inclusive cost for the relevant documents and
            registration of the security interest on the PPSR is $788 (inclusive
            of GST). A tax invoice will be available for download together with
            the rest of the suite of documents.
          </p>
          <p>
            Our experience has been that lawyers charge between $5,000 and
            upwards of $10,000 to prepare the necessary documentation and
            register the security interest on the PPSR. This cost is usually far
            too much for a new business owner to spend on something that they
            may never need.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              Do I need an adviser (accountant or lawyer) to complete the form?
            </strong>
          </p>
          <p>
            The forms can be filled out on your behalf by your adviser. However,
            the questions have been designed so that if you know some basic
            details, small-business owners will be able to fill out the form
            themselves.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              In what countries can I use{" "}
              <strong className={classNames(styles.highlight)}>krodok</strong>?
            </strong>
          </p>
          <p>
            At the moment,{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong> is
            available only in Australia. We have plans to roll it out to New
            Zealand, Canada and the United Kingdom in the near future. Whilst
            the suite of documents in Australia will be very similar to the
            documents in other countries, there may be subtle differences that
            may have mean the documents may not be used as intended. Also,{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
            does not currently have the ability to register securities in other
            jurisdictions. But, watch this space!
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>Are the documents legally enforceable?</strong>
          </p>
          <p>
            The documents are binding and legally enforceable. We constantly
            review and update our documents to keep up with any changes in the
            law. However, the documents do not protect poor behaviour of owners
            who do not live up to their legal obligations as directors that are
            owed to the company and other stakeholders.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              What if there is more than one lender, at different times and/or
              in different amounts?
            </strong>
          </p>
          <p>
            More than one lender can be included in the documents and they can
            be covered by the same registration. In circumstances that owners
            have made loans at different times, it may be necessary to put into
            place a side agreement between those owners as to the priority of
            their loans in what is called an intercreditor deed.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              Can I protect amounts that I have invested in my business over the
              years?
            </strong>
          </p>
          <p>
            It is possible to protect amounts of money loaned by owners to the
            business over the years. However, in order to do that, it is
            necessary to set up a situation where the owner as lender gives new
            consideration (see below) by way of a forbearance (see below) in
            order to get security from the company to secure all of the money
            loaned.
          </p>
          <p>
            The owner often loans amounts of money to their business over the
            years. There is no requirement to have a written document to have an
            enforceable loan. However, without a written document, there are no
            stated terms as to the repayment of the loan. That leaves open the
            opportunity for the owners to make a demand of the company for the
            repayment of the loan. When the company cannot pay, it will propose
            a forbearance of the owner’s ability to enforce to right of
            repayment. On the basis that the owner has given new consideration
            in that it agrees not to enforce their rights. That is arguably
            sufficient to be able to secure all money that has been loaned.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>What is insolvency?</strong>
          </p>
          <p>
            In Australia, insolvency is defined as being when a company is
            unable to pay its debts (with reasonably available cash) to pay when
            they are due and payable. Upon the occurrence of insolvency, an
            external insolvency practitioner (usually an accountant) will be
            appointed to run the company. Insolvency can be sudden and can lead
            to owners losing their business because they lose control of the
            company and cannot afford to pay.
          </p>
          <p>
            It is important to protect yourself against the effect of
            insolvency, so that you can restructure the debts of the company and
            it can thrive again as soon as possible. The bank comes out of the
            insolvency process better than all other creditors because they have
            a registered secured loan. The controls the insolvency process.
            <strong className={classNames(styles.highlight)}>krodok</strong> can
            help to put you in the position of the bank and control the
            insolvency process.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>
              What happens if I think that my company may be approaching
              insolvency?
            </strong>
          </p>
          <p>
            If you think that your company may be approaching insolvency, it is
            important to get advice from insolvency specialists early. If you
            have{" "}
            <strong className={classNames(styles.highlight)}>krodok</strong>,
            you will have options that you can speak to insolvency specialists
            about, who can map a quick and cost-effective way forward to have
            your business thrive again. Too often, owners wait too long to get
            advice (and usually are not properly protected) and all that the
            insolvency practitioner can reasonably do is nail in the last nail
            to the coffin.
          </p>
          <p>
            If you do not have a trusted insolvency specialist to approach,
            contact us on{" "}
            <a
              href="mailto:inquiries@krodok.com.au"
              className={classNames(styles.highlight)}
            >
              inquiries@krodok.com.au
            </a>{" "}
            and we can discuss with you appropriate insolvency specialists who
            can best assist you.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>What is the PPSR?</strong>
          </p>
          <p>
            The PPSR is the register of security interest maintained under the
            Personal Property Securities Act 2012 (Cth). To be effective and
            enforceable, all security interests must be registered on the PPSR.
            This is especially important in the event of insolvency.
          </p>
        </div>

        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>What is a forbearance and why is it necessary?</strong>
          </p>
          <p>
            Forbearance is the agreement by a party not to enforce its rights on
            the counter party for a period of time. In a legal context, that is
            usually contained in a forbearance deed. It is necessary in this
            context to have the owners give new consideration in order to get a
            general security agreement over the money that it has loaned since
            the incorporation of the company.
          </p>
        </div>
        <div className={classNames(styles.questionSection)}>
          <p>
            <strong>What is “consideration”?</strong>
          </p>
          <p>
            Consideration is essentially the giving of something by each party
            to an agreement. It is the bargain that the parties strike with one
            another at the time they enter into an agreement. In this context,
            if owners have loaned money, that is pursuant to a contract whether
            or not that is reduced to writing. If at the time of that contract,
            there was no agreement that in exchange for loan money, the owner
            would get a general security agreement over the assets of the
            company to secure the obligations to repay the loan then the owner
            cannot later get a general security agreement without giving
            something more. As stated above, in this case the new consideration
            is the owner agreeing not to enforce their rights to have the
            company repay the loan.
          </p>
        </div>
      </GridContainer>
    );
  }

  /** Renders the component */
  render() {
    return (
      <div className="page-layout">
        <div className={styles.container}>
          <GridContainer xsSpan={4} smSpan={8} mdSpan={12}>
            <h1 className={classNames(styles.pageTitle)}>How It Works</h1>
            <p className={classNames(styles.summary)}>
              <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
              is a digital tool designed for you. if you have invested in your
              company and want to secure your future, you need{" "}
              <strong className={classNames(styles.highlight)}>krodok</strong>.
              it automatically generates legally-enforceable documents that
              raises you to being a first-ranking creditor of your business
              without the fuss or expense of dealing with lawyers
            </p>
          </GridContainer>
          {this.renderWhatSection()}
          {this.renderHowSection()}
          {this.renderWhySection()}
          {this.renderFAQSection()}
        </div>
      </div>
    );
  }
}
