import { HttpFetch, HttpRequest } from "@hx/hx/service/http";

import { Paginated, Unit } from "../adl-gen/common";
import * as AR from "../adl-gen/common/adminui/api";
import { MetaAdlDecl } from "../adl-gen/common/adminui/db";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import {
  HttpGet,
  HttpPost,
  snHttpGet,
  snHttpPost
} from "../adl-gen/common/http";
import { TableQuery } from "../adl-gen/common/tabular";
import * as R from "../adl-gen/krodok/snappy/api";
import * as DB from "../adl-gen/krodok/snappy/db";
import { ATypeExpr, DeclResolver } from "../adl-gen/runtime/adl";
import {
  createJsonBinding,
  getAnnotation,
  JsonBinding
} from "../adl-gen/runtime/json";
import { scopedNamesEqual } from "../adl-gen/runtime/utils";
import * as adlast from "../adl-gen/sys/adlast";

import { HttpServiceError } from "./http-service-error";
import { Service } from "./service";
import { TokenManager } from "./token-manager";

/**
 * Combines a async function to make a get request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface GetFn<O> {
  description(): string;
  rtype: HttpGet<O>;
  call(): Promise<O>;
}

/**
 * Combines a async function to make a post request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface PostFn<I, O> {
  description(): string;
  rtype: HttpPost<I, O>;
  call(req: I): Promise<O>;
}

interface RequestAttributes {
  actionName: string;
  description: string;
}

/**
 * The protoapp backend service.
 */
export class HttpService implements Service {
  requests: R.ApiRequests;
  requestsDecl: adlast.ScopedDecl;

  postLogin: PostFn<R.LoginReq, R.LoginResp>;
  getWhoAmI: GetFn<R.UserProfile>;
  postCreateUser: PostFn<R.UserReq, DbKey<DB.AppUser>>;
  postUpdateUser: PostFn<WithDbId<R.UserReq>, Unit>;
  postDeleteUser: PostFn<DbKey<DB.AppUser>, Unit>;
  postQueryUsers: PostFn<TableQuery, Paginated<WithDbId<DB.AppUser>>>;

  postCreateApplication: PostFn<DB.CompanyType, DbKey<DB.Application>>;
  postUpdateApplication: PostFn<
    WithDbId<R.ApplicationReq>,
    R.UpdateApplicationResp
  >;
  postQueryApplication: PostFn<R.QueryApplicationReq, R.QueryApplicationResp>;

  getCreatePaymentIntent: PostFn<
    R.CreatePaymentIntentReq,
    R.CreatePaymentIntentResp
  >;
  getCheckPaymentIntent: PostFn<
    DbKey<DB.Application>,
    R.CheckPaymentIntentResp
  >;

  postNewMessage: PostFn<R.NewMessageReq, Unit>;
  postRecentMessages: PostFn<R.RecentMessagesReq, Paginated<R.Message>>;

  postAdminQueryTables: PostFn<TableQuery, Paginated<AR.Table>>;
  postAdminQueryDecls: PostFn<TableQuery, Paginated<MetaAdlDecl>>;
  postAdminCreate: PostFn<AR.CreateReq, AR.DbResult<DbKey<AR.DbRow>>>;
  postAdminQuery: PostFn<AR.QueryReq, Paginated<WithDbId<AR.DbRow>>>;
  postAdminUpdate: PostFn<AR.UpdateReq, AR.DbResult<Unit>>;
  postAdminDelete: PostFn<AR.DeleteReq, AR.DbResult<Unit>>;

  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** Resolver for ADL types */
    private readonly resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    private readonly tokenManager: TokenManager,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void
  ) {
    this.requests = R.makeApiRequests({});
    this.requestsDecl = resolver(R.snApiRequests);

    this.postLogin = this.mkPostFn(this.requests.login);
    this.getWhoAmI = this.mkGetFn(this.requests.whoAmI);
    this.postCreateUser = this.mkPostFn(this.requests.createUser);
    this.postUpdateUser = this.mkPostFn(this.requests.updateUser);
    this.postDeleteUser = this.mkPostFn(this.requests.deleteUser);
    this.postQueryUsers = this.mkPostFn(this.requests.queryUsers);
    this.postNewMessage = this.mkPostFn(this.requests.newMessage);
    this.postRecentMessages = this.mkPostFn(this.requests.recentMessages);

    this.postCreateApplication = this.mkPostFn(this.requests.createApplication);
    this.postUpdateApplication = this.mkPostFn(this.requests.updateApplication);
    this.postQueryApplication = this.mkPostFn(this.requests.queryApplication);

    this.getCreatePaymentIntent = this.mkPostFn(
      this.requests.createPaymentIntentSecret
    );
    this.getCheckPaymentIntent = this.mkPostFn(
      this.requests.checkPaymentIntent
    );

    this.postAdminQueryTables = this.mkPostFn(this.requests.admin.queryTables);
    this.postAdminQueryDecls = this.mkPostFn(this.requests.admin.queryDecls);
    this.postAdminCreate = this.mkPostFn(this.requests.admin.create);
    this.postAdminQuery = this.mkPostFn(this.requests.admin.query);
    this.postAdminUpdate = this.mkPostFn(this.requests.admin.update);
    this.postAdminDelete = this.mkPostFn(this.requests.admin.delete);
  }

  async login(req: R.LoginReq): Promise<R.LoginResp> {
    return this.postLogin.call(req);
  }

  async whoami(): Promise<R.UserProfile> {
    return this.getWhoAmI.call();
  }

  async createUser(req: R.UserReq): Promise<DbKey<DB.AppUser>> {
    return this.postCreateUser.call(req);
  }

  async updateUser(req: WithDbId<R.UserReq>): Promise<void> {
    await this.postUpdateUser.call(req);
  }

  async deleteUser(req: DbKey<R.UserReq>): Promise<void> {
    await this.postDeleteUser.call(req);
  }

  async queryUsers(req: TableQuery): Promise<Paginated<WithDbId<DB.AppUser>>> {
    return this.postQueryUsers.call(req);
  }

  async newMessage(req: R.NewMessageReq): Promise<void> {
    await this.postNewMessage.call(req);
  }

  async recentMessages(
    req: R.RecentMessagesReq
  ): Promise<Paginated<R.Message>> {
    return this.postRecentMessages.call(req);
  }

  async adminQueryTables(req: TableQuery): Promise<Paginated<AR.Table>> {
    return this.postAdminQueryTables.call(req);
  }

  async adminQueryDecls(req: TableQuery): Promise<Paginated<MetaAdlDecl>> {
    return this.postAdminQueryDecls.call(req);
  }

  async adminCreate(req: AR.CreateReq): Promise<AR.DbResult<DbKey<AR.DbRow>>> {
    return this.postAdminCreate.call(req);
  }

  async adminQuery(req: AR.QueryReq): Promise<Paginated<WithDbId<AR.DbRow>>> {
    return this.postAdminQuery.call(req);
  }

  async adminUpdate(req: AR.UpdateReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminUpdate.call(req);
  }

  async adminDelete(req: AR.DeleteReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminDelete.call(req);
  }

  async createApplication(req: DB.CompanyType): Promise<string> {
    return this.postCreateApplication.call(req);
  }

  async updateApplication(
    req: WithDbId<R.ApplicationReq>
  ): Promise<R.UpdateApplicationResp> {
    return this.postUpdateApplication.call(req);
  }

  async queryApplication(
    req: R.QueryApplicationReq
  ): Promise<R.QueryApplicationResp> {
    return this.postQueryApplication.call(req);
  }

  async createPaymentIntent(
    req: R.CreatePaymentIntentReq
  ): Promise<R.CreatePaymentIntentResp> {
    return this.getCreatePaymentIntent.call(req);
  }

  async checkPaymentIntent(
    req: DbKey<DB.Application>
  ): Promise<R.CheckPaymentIntentResp> {
    return this.getCheckPaymentIntent.call(req);
  }

  private mkGetFn<O>(rtype: HttpGet<O>): GetFn<O> {
    const jb = createJsonBinding(this.resolver, rtype.respType);
    const { actionName, description } = this.getRequestAttributes(
      snHttpGet,
      rtype.path
    );
    return {
      description: () => description,
      rtype,
      call: () => {
        return this.getAdl(rtype.path, jb, actionName);
      }
    };
  }

  private mkPostFn<I, O>(rtype: HttpPost<I, O>): PostFn<I, O> {
    const bb = createBiBinding<I, O>(this.resolver, rtype);
    const { actionName, description } = this.getRequestAttributes(
      snHttpPost,
      rtype.path
    );
    return {
      description: () => description,
      rtype,
      call: (req: I) => {
        return this.postAdl(rtype.path, bb, req, actionName);
      }
    };
  }

  private getRequestAttributes(
    method: adlast.ScopedName,
    path: string
  ): RequestAttributes {
    if (this.requestsDecl.decl.type_.kind !== "struct_") {
      throw new Error("BUG: requestDecl is not a struct");
    }
    const struct = this.requestsDecl.decl.type_.value;
    const attrs = this.getRequestAttributesFromApiStruct(
      this.requests,
      struct,
      method,
      path
    );
    if (attrs === undefined) {
      //tslint:disable:no-console
      console.log("WARNING: field not found for path ", method, path);
      return { actionName: "??", description: "??" };
    }
    return attrs;
  }

  private getRequestAttributesFromApiStruct(
    requests: {},
    struct: adlast.Struct,
    method: adlast.ScopedName,
    path: string
  ): RequestAttributes | undefined {
    for (const field of struct.fields) {
      const req = requests[field.name];
      if (field.typeExpr.typeRef.kind === "reference") {
        if (scopedNamesEqual(field.typeExpr.typeRef.value, method)) {
          if (req.path === path) {
            return {
              actionName: field.name,
              description:
                getAnnotation(
                  createJsonBinding(this.resolver, texprDocString),
                  field.annotations
                ) || ""
            };
          }
        } else {
          const decl = this.resolver(field.typeExpr.typeRef.value);
          if (decl.decl.type_.kind === "struct_") {
            const attrs = this.getRequestAttributesFromApiStruct(
              req,
              decl.decl.type_.value,
              method,
              path
            );
            if (attrs !== undefined) {
              return attrs;
            }
          }
        }
      }
    }
    return undefined;
  }

  private async getAdl<O>(
    path: string,
    respJB: JsonBinding<O>,
    actionName: string
  ): Promise<O> {
    return this.requestAdl("get", path, null, respJB, actionName);
  }

  private async postAdl<I, O>(
    path: string,
    post: BiBinding<I, O>,
    req: I,
    actionName: string
  ): Promise<O> {
    const jsonArgs = post.reqJB.toJson(req);
    return this.requestAdl("post", path, jsonArgs, post.respJB, actionName);
  }

  private async requestAdl<O>(
    method: "get" | "post",
    path: string,
    jsonArgs: {} | null,
    respJB: JsonBinding<O>,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string
  ): Promise<O> {
    // Construct request
    const authToken = this.tokenManager.getToken();
    const headers: { [key: string]: string } = {};
    if (authToken) {
      headers["X-Auth-Token"] = authToken;
    }
    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonArgs ? JSON.stringify(jsonArgs) : undefined
    };

    // Make request
    const resp = await this.http.fetch(httpReq);

    // Check for errors
    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = `: ${bodyJson.publicMessage}`;
        }
      } catch (e) {
        // Not JSON
      }

      const error = new HttpServiceError(
        `Encountered server error attempting to call ${actionName} ${publicMessageFragment}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${
          resp.statusText
        } (${resp.status}): ${bodyText}`,
        resp.status
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      const respJson = await resp.json();
      return respJB.fromJson(respJson);
    } catch (e) {
      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        e.getMessage(),
        resp.status
      );
      this.handleError(error);
      throw error;
    }
  }
}

interface BiTypeExpr<I, O> {
  reqType: ATypeExpr<I>;
  respType: ATypeExpr<O>;
}

interface BiBinding<I, O> {
  reqJB: JsonBinding<I>;
  respJB: JsonBinding<O>;
}

function createBiBinding<I, O>(
  resolver: DeclResolver,
  rtype: BiTypeExpr<I, O>
): BiBinding<I, O> {
  return {
    reqJB: createJsonBinding(resolver, rtype.reqType),
    respJB: createJsonBinding(resolver, rtype.respType)
  };
}

const texprDocString: ATypeExpr<string> = {
  value: {
    typeRef: {
      kind: "reference",
      value: { moduleName: "sys.annotations", name: "Doc" }
    },
    parameters: []
  }
};
