export interface AppLinks {
  getHomepageLink(): string;
  getTermsAndConditionsLink(): string;
  getPrivacyPolicyLink(): string;
  getLoanSuiteApplicationLink(): string;
  getReviewLink(): string;
  getCheckoutLink(): string;
  getConfirmationLink(): string;
  getLearnMoreLink(): string;
  getAboutUsLink(): string;
}

export function appLinks(): AppLinks {
  return {
    getHomepageLink(): string {
      return "/";
    },
    getTermsAndConditionsLink(): string {
      return "/terms-and-conditions";
    },
    getPrivacyPolicyLink(): string {
      return "/privacy-policy";
    },
    getLoanSuiteApplicationLink(): string {
      return "/loan-suite-application";
    },
    getReviewLink(): string {
      return "/review-and-confirm";
    },
    getCheckoutLink(): string {
      return "/checkout";
    },
    getConfirmationLink(): string {
      return "/confirm";
    },
    getLearnMoreLink(): string {
      return "/learn-more";
    },
    getAboutUsLink(): string {
      return "/about-us";
    }
  };
}

export function dummyAppLinks(): AppLinks {
  return {
    getHomepageLink(): string {
      return "dummy-homepage";
    },
    getTermsAndConditionsLink(): string {
      return "dummy-terms-and-conditions";
    },
    getPrivacyPolicyLink(): string {
      return "dummy-privacy-policy";
    },
    getLoanSuiteApplicationLink(): string {
      return "dummy-loan-suite-application";
    },
    getReviewLink(): string {
      return "dummy-review-link";
    },
    getCheckoutLink(): string {
      return "dummy-checkout-link";
    },
    getConfirmationLink(): string {
      return "dummy-confirmation-link";
    },
    getLearnMoreLink(): string {
      return "dummy-learn-more";
    },
    getAboutUsLink(): string {
      return "dummy-about-us";
    }
  };
}
