/** A typesafe route */

export type Route =
  | { route: "loan-suite-application"; applicationId: string }
  | { route: "home" }
  | { route: "review-and-confirm"; applicationId: string }
  | { route: "checkout"; applicationId: string; secret?: string }
  | { route: "confirm"; applicationId: string }
  | { route: "terms-and-conditions" }
  | { route: "privacy-policy" }
  | { route: "learn-more" }
  | { route: "about-us" };

export function routeFromPath(path: string): Route | undefined {
  if (path.startsWith("/")) {
    const parts = path
      .substr(1)
      .split("/")
      .map(decodeURIComponent);
    if (parts.length === 2 && parts[0] === "loan-suite-application") {
      return { route: "loan-suite-application", applicationId: parts[1] };
    } else if (parts.length === 1 && parts[0] === "home") {
      return { route: "home" };
    } else if (parts.length === 2 && parts[0] === "review-and-confirm") {
      return { route: "review-and-confirm", applicationId: parts[1] };
    } else if (parts.length === 3 && parts[0] === "checkout") {
      return { route: "checkout", applicationId: parts[1], secret: parts[2] };
    } else if (parts.length === 2 && parts[0] === "checkout") {
      return { route: "checkout", applicationId: parts[1] };
    } else if (parts.length === 2 && parts[0] === "confirm") {
      return { route: "confirm", applicationId: parts[1] };
    } else if (parts.length === 1 && parts[0] === "terms-and-conditions") {
      return { route: "terms-and-conditions" };
    } else if (parts.length === 1 && parts[0] === "privacy-policy") {
      return { route: "privacy-policy" };
    } else if (parts.length === 1 && parts[0] === "learn-more") {
      return { route: "learn-more" };
    } else if (parts.length === 1 && parts[0] === "about-us") {
      return { route: "about-us" };
    }
  }
  return undefined;
}

export function pathFromRoute(route: Route): string {
  switch (route.route) {
    case "loan-suite-application":
      return "/loan-suite-application" + route.applicationId;
    case "home":
      return "/";
    case "review-and-confirm":
      return "review-and-confirm" + route.applicationId;
    case "checkout":
      return "checkout" + route.applicationId;
    case "confirm":
      return "confirm" + route.applicationId;
    case "terms-and-conditions":
      return "terms-and-conditions";
    case "privacy-policy":
      return "privacy-policy";
    case "learn-more":
      return "learn-more";
    case "about-us":
      return "about-us";
  }
}
