import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { AppStore } from "../../../stores/app-store";
import { AppLinks } from "../../../stores/shared/app-links";
import { FullWidth } from "../../common/full-width/full-width";
import { GridContainer } from "../../common/grid-container/grid-container";
import { Building, Group, Mail } from "../../common/icon/icons";
import { ClickableTile } from "../../widgets/clickable-tile/clickable-tile";
import { ResponsiveImage } from "../../widgets/responsive-image/responsive-image";

const styles = require("./home-page.css");
const hero = require("../../../assets/images/hero.png");
const piggy = require("../../../assets/images/piggy.png");
const waterfall = require("../../../assets/images/waterfall.png");

/** Props for the component */
export interface HomeProps {
  /** App links */
  appLinks: AppLinks;
  store: AppStore;
}

/**
 * The Home page.
 */
@observer
export class HomePage extends React.Component<HomeProps> {
  renderAboutSection() {
    return (
      <FullWidth gridContent className={classNames(styles.panel)}>
        <GridContainer
          xsSpan={4}
          smSpan={4}
          mdSpan={6}
          className={styles.details}
        >
          <p className={styles.hero}>protect.</p>
          <p className={classNames(styles.hero, styles.highlight)}>empower.</p>
          <p className={styles.hero}>survive.</p>
          <p className={styles.bodyLarge}>
            Get peace of mind, knowing your secured loans are registered and
            protected against insolvency
            <br />
            All for a one-off payment of $788
          </p>
        </GridContainer>
        <GridContainer xsSpan={4} smSpan={4} mdSpan={6}>
          <ResponsiveImage
            src={hero}
            imgClassName={classNames(styles.sectionImage)}
          />
        </GridContainer>
      </FullWidth>
    );
  }

  renderLoansSection() {
    return (
      <FullWidth
        gridContent
        className={classNames(styles.panel, styles.greyBackground)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="0,100,100,0,0,0" />
        </svg>
        <div className={styles.tiles}>
          <GridContainer xsSpan={4} smSpan={4} mdSpan={6}>
            <ClickableTile
              className={styles.whiteBackground}
              Icon={Building}
              title="Existing Companies"
              text="Protect your money invested as a secured loan and register it on the PPSR to protect yourself against insolvency"
              ctaText="GET STARTED"
              onClick={
                this.props.store.getApplicationStore()
                  .createExistingCompanyApplication
              }
            />
          </GridContainer>
          <GridContainer xsSpan={4} smSpan={4} mdSpan={6}>
            <ClickableTile
              className={styles.whiteBackground}
              Icon={Group}
              title="New Companies"
              text="Protect your seed investment as a secured loan and register it on the PPSR to protect yourself against insolvency"
              ctaText="GET STARTED"
              onClick={
                this.props.store.getApplicationStore()
                  .createNewCompanyApplication
              }
            />
          </GridContainer>
        </div>
      </FullWidth>
    );
  }

  renderImportanceSection() {
    return (
      <FullWidth
        gridContent
        className={classNames(styles.panel, styles.greyBackground)}
      >
        <div className={styles.importance}>
          <GridContainer xsSpan={4}>
            <h1 className={styles.sectionTitle}>Why is this important?</h1>
          </GridContainer>
          <GridContainer xsSpan={4} smSpan={4} mdSpan={6}>
            <ResponsiveImage src={piggy} />
          </GridContainer>
          <GridContainer
            xsSpan={4}
            smSpan={4}
            mdSpan={6}
            className={styles.details}
          >
            <p>
              Small business founders invest a significant amount of their own
              money into their businesses, at start-up and over the years. That
              investment is usually not properly protected.
            </p>
            <p>
              When a business becomes insolvent, the bank directs the process
              and gets the best outcome because it is properly secured. It is
              like a mortgage over a house. Founders generally get nothing back
              and lose their business.
            </p>
            <p className={classNames(styles.bold)}>
              Small business founders need to protect themselves like a bank
              with a registered secured loan.
            </p>
          </GridContainer>
        </div>
      </FullWidth>
    );
  }

  renderExplanationSection() {
    return (
      <FullWidth
        gridContent
        className={classNames(styles.panel, styles.tertiaryBackground)}
      >
        <GridContainer xsSpan={4}>
          <h1 className={classNames(styles.sectionTitle, styles.fontWhite)}>
            How does this work?
          </h1>
        </GridContainer>
        <GridContainer
          xsSpan={4}
          smSpan={4}
          mdSpan={6}
          className={classNames(styles.details, styles.fontWhite)}
        >
          <p>
            In the event of insolvency, the waterfall under section 556 of the
            Corporations Act prioritises payment of debts to secured creditors,
            priority creditors, and trade or unsecured creditors over
            shareholders.
          </p>
          <p>
            krodok is a digital tool designed for you. if you have invested in
            your company and want to secure your future, you need krodok. it
            automatically generates legally-enforceable documents that raises
            you to being a first-ranking creditor of your business without the
            fuss or expense of dealing with lawyers.{" "}
            <a
              className={styles.inlineLink}
              href={this.props.appLinks.getLearnMoreLink()}
            >
              Learn More
            </a>
          </p>
        </GridContainer>
        <GridContainer xsSpan={4} smSpan={4} mdSpan={6}>
          <ResponsiveImage src={waterfall} />
        </GridContainer>
      </FullWidth>
    );
  }

  renderContactSection() {
    return (
      <FullWidth
        gridContent
        className={classNames(styles.panel, styles.greyBackground)}
      >
        <GridContainer xsSpan={4}>
          <h1 className={styles.sectionTitle}>Get in touch</h1>
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={6} className={styles.details}>
          <p className={styles.bodyLarge}>
            We’re focused on making your loan registration as simple as possible
            so don’t hesitate to reach out
          </p>
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={6} className={styles.details}>
          <a href="mailto:inquiries@krodok.com.au">
            <div className={styles.contactWrapper}>
              <Mail size={"xsmall"} />
              <p className={classNames(styles.contact, styles.underline)}>
                inquiries@krodok.com.au
              </p>
            </div>
          </a>
        </GridContainer>
      </FullWidth>
    );
  }

  /** Renders the component */
  render() {
    return (
      <div className="page-layout">
        <div className="container">
          {this.renderAboutSection()}
          {this.renderLoansSection()}
          {this.renderImportanceSection()}
          {this.renderExplanationSection()}
          {this.renderContactSection()}
        </div>
      </div>
    );
  }
}
