import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { AppLinks } from "../../../stores/shared/app-links";
import { PublicFooter } from "../../widgets/public-footer/public-footer";
import { PublicHeader } from "../../widgets/public-header/public-header";
import { PageBody } from "../page-body/page-body";

const styles = require("./page-layout.css");

export interface LinkItem {
  text: string;
  onClick(): void;
}

export interface Menu {
  title: string;
  items: LinkItem[];
}

/** Props for the component */
export interface PageLayoutProps {
  /** Page title */
  title: string;

  /** Elements of the primary content area */
  children?: React.ReactNode;

  /** Links to various site areas */
  appLinks: AppLinks;

  /** The menu */
  menu?: Menu;
}

/**
 * Layout for typical pages.
 */
@observer
export class PageLayout extends React.Component<PageLayoutProps> {
  /** Renders the content body */
  renderBody() {
    return (
      <PageBody
        className={styles.pageBody}
        contentClassName={styles.pageContent}
      >
        {this.props.children}
      </PageBody>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /** Renders the page */
  render() {
    return (
      <div className={styles.page}>
        <div className={styles.headerContainer}>
          <PublicHeader
            appLinks={this.props.appLinks}
            className={classNames(styles.headerContainer)}
          />
        </div>
        {this.renderBody()}
        <div className={styles.footerBackground}>
          <div className={styles.footerContainer}>
            <PublicFooter appLinks={this.props.appLinks} />
          </div>
        </div>
      </div>
    );
  }
}
