const colorStyles = require("../../common/colors/colors.css");
const fontStyles = require("../../common/fonts/fonts.css");
// const metricStyles = require("../../common/metrics.css");

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "Open SansRegular",
      fontSmoothing: "antialiased",
      fontSize: fontStyles.defaultFontSize,
      color: colorStyles.colorGreyDark
    },
    empty: {
      "::placeholder": {
        fontFamily: "Open SansRegular",
        fontSmoothing: "antialiased",
        fontSize: fontStyles.defaultFontSize,
        color: colorStyles.colorGreyDark
      }
    },
    complete: {
      color: colorStyles.colorPrimary
    },
    invalid: {
      color: colorStyles.colorError
    }
  }
};
