import { observer } from "mobx-react";
import * as React from "react";

import {
  CheckPaymentIntentResp,
  CreatePaymentIntentResp
} from "../../../adl-gen/krodok/snappy/api";
import { GridContainer } from "../../common/grid-container/grid-container";
import {
  CardElement,
  StripeCheckoutForm
} from "../../widgets/stripe-checkout/stripe-checkout";

/** Props for the component */
export interface CheckoutProps {
  cardElements: CardElement[];
  getClientSecret(): Promise<CreatePaymentIntentResp | undefined>;
  checkPayment(): Promise<CheckPaymentIntentResp | undefined>;
  paymentFinished(): Promise<void>;
  stripePublicKey: string;
}

/**
 * The Checkout page.
 */
@observer
export class CheckoutPage extends React.Component<CheckoutProps> {
  /** Renders the component */
  render() {
    return (
      <div className="page-layout">
        <div className="container">
          <GridContainer mdSpan={12} xsSpan={4}>
            <h1>Payment details</h1>
            <p>
              One-off payment of <strong>$788</strong> (incl GST)
            </p>
          </GridContainer>
          <StripeCheckoutForm
            stripePublicKey={this.props.stripePublicKey}
            getClientSecret={this.props.getClientSecret}
            checkPayment={this.props.checkPayment}
            paymentFinished={this.props.paymentFinished}
            cardElements={this.props.cardElements}
          />
        </div>
      </div>
    );
  }
}
