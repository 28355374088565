import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { TextInput, TextInputProps } from "../text-input/text-input";

const styles = require("./multi-text-question.css");

/** Question definition */
export interface MultiTextQuestionProps {
  /** Additional classes to apply */
  className?: string;
  /** Text of the question */
  questionText: string;
  /** Hint text for the question */
  hintText?: string;
  /** List of text inputs */
  inputs: MultiTextInput[];
}

export interface MultiTextInput {
  /** Callback when one of the values changes */
  onChange(value: string): void;
  /** Placeholder text */
  placeholderText?: string;
  /** Entered input value */
  value: string;
  /** Error message */
  errorMessage?: string;
  /** Name of the input */
  name?: string;
  /** Autocomplete */
  autoComplete?: "on" | "off" | "new-password";
  /** Specifies the input type */
  inputType?: TextInputProps["type"];
  /** Minimum Length */
  minLength?: number;
  /** Maximum Length */
  maxLength?: number;
  /** Hint text */
  helpText?: string;
}

export class MultiTextInputImpl implements MultiTextInput {
  @observable.ref
  value: string;

  @observable.ref
  errorMessage: string | undefined;

  placeholderText?: string;
  helpText?: string;
  name?: string;
  inputType?: TextInputProps["type"];
  minLength?: number;
  maxLength?: number;
  setter: (v: string) => void;

  constructor(v: {
    placeholderText: string;
    hintText?: string;
    name: string;
    inputType?: TextInputProps["type"];
    minLength?: number;
    maxLength?: number;
    getter(): string;
    setter(v: string): void;
    getError?(): string | undefined;
  }) {
    this.placeholderText = v.placeholderText;
    this.helpText = v.hintText;
    this.name = v.name;
    this.value = v.getter();
    this.errorMessage = v.getError === undefined ? undefined : v.getError();
    this.setter = v.setter;
    this.inputType = v.inputType;
    this.minLength = v.minLength;
    this.maxLength = v.maxLength;
  }

  onChange = (v: string): void => {
    this.value = v;
    this.setter(v);
  };
}

/**
 * A question with multiple text free fields
 */
@observer
export class MultiTextQuestion extends React.Component<MultiTextQuestionProps> {
  /** Renders a text input */
  renderTextInput(input: MultiTextInput, idx: number) {
    return (
      <div key={idx} className={styles.input}>
        {input.helpText && (
          <GridContainer xsSpan={4}>
            <div>{input.helpText}</div>
          </GridContainer>
        )}
        <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
          <TextInput
            type={input.inputType || "text"}
            placeholder={input.placeholderText || "Please enter"}
            value={input.value}
            onChange={input.onChange}
            errorMessage={input.errorMessage}
            name={input.name}
            autoComplete={input.autoComplete}
            maxLength={input.maxLength || 255}
            minLength={input.minLength || 0}
          />
        </GridContainer>
      </div>
    );
  }

  /** Renders the question */
  render() {
    return (
      <div className={this.props.className}>
        <GridContainer xsSpan={4} className={styles.questionText}>
          <h2 className={styles.questionText}>{this.props.questionText}</h2>
          {this.props.hintText && (
            <div className={styles.hintText}>{this.props.hintText}</div>
          )}
        </GridContainer>
        <div className={styles.textInputs}>
          {this.props.inputs.map((input, idx) => {
            return this.renderTextInput(input, idx);
          })}
        </div>
      </div>
    );
  }
}
