import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

const styles = require("./grid-container.css");

/** Props for the component */
export interface GridContainerProps {
  /** Chidren of the container */
  children?: React.ReactNode;
  /** Style name to apply to root element */
  className?: string;
  /** Style name to apply to the inner content element */
  contentClassName?: string;
  /** Vertical alignment within row of inline containers. Default is `top`. */
  verticalAlign?: "top" | "bottom";
  /** Columns to span from XS size and above */
  xsSpan?: 1 | 2 | 3 | 4;
  /** Columns to offset from XS size and above */
  xsOffset?: 1 | 2 | 3;
  /** Columns to span from SM size and above */
  smSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /** Columns to offset from SM size and above */
  smOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /** Columns to span from MD size and above */
  mdSpan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /** Columns to offset from MD size and above */
  mdOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  /** Whether to show the debug overlay */
  debug?: boolean;
}

/**
 * Basic unit of layout within the responsive grid. Contrains children to a
 * number of columns for each viewport size.
 */
export const GridContainer: React.SFC<GridContainerProps> = observer(
  ({
    children,
    className,
    contentClassName,
    verticalAlign = "top",
    mdSpan,
    mdOffset,
    smSpan,
    smOffset,
    xsSpan,
    xsOffset,
    debug
  }: GridContainerProps) => {
    return (
      <div
        className={classnames(
          styles.container,
          className,
          styles["verticalAlign-" + verticalAlign],
          xsSpan ? styles.xsVisible : null,
          smSpan ? styles.smVisible : null,
          mdSpan ? styles.mdVisible : null,
          xsSpan ? styles[`xsSpan${xsSpan}`] : null,
          xsOffset ? styles[`xsOffset${xsOffset}`] : null,
          smSpan ? styles[`smSpan${smSpan}`] : null,
          smOffset !== undefined ? styles[`smOffset${smOffset}`] : null,
          mdSpan ? styles[`mdSpan${mdSpan}`] : null,
          mdOffset !== undefined ? styles[`mdOffset${mdOffset}`] : null
        )}
      >
        <div className={classnames(styles.content, contentClassName)}>
          <>
            {debug ? <div className={styles.debugOverlay} /> : undefined}
            {children}
          </>
        </div>
      </div>
    );
  }
);
