import { observer } from "mobx-react";
import * as React from "react";

import { PageBody } from "../page-body/page-body";

const styles = require("./form-page-layout.css");

/** Props for the component */
export interface FormPageLayoutProps {
  /** Header component */
  Header?: React.ReactType<{ contentClassName?: string }>;
  /** Footer component */
  Footer?: React.ReactType<{ contentClassName?: string }>;
  /** Elements of the primary content area */
  children?: React.ReactNode;
  /** Whether to render the responsive grid layout overlay */
  debug?: boolean;
}

/**
 * Layout for a page in the public section of the site.
 */
@observer
export class FormPageLayout extends React.Component<FormPageLayoutProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /** Renders the page */
  render() {
    return (
      <div className={styles.page}>
        {this.props.Header ? (
          <div className={styles.headerContainer}>
            <this.props.Header contentClassName={styles.headerContent} />
          </div>
        ) : (
          undefined
        )}
        <PageBody
          className={styles.pageBody}
          contentClassName={styles.pageContent}
          debug={this.props.debug}
        >
          {this.props.children}
        </PageBody>
        {this.props.Footer ? (
          <div className={styles.footerBackground}>
            <div className={styles.footerContainer}>
              <this.props.Footer contentClassName={styles.footerContent} />
            </div>
          </div>
        ) : (
          undefined
        )}
      </div>
    );
  }
}
