import React from "react";

export class SecuredLoanRegisterIntro extends React.Component {
  render() {
    return (
      <>
        <p>
          This set of questions capture the necessary details in order for you
          to register your secured loan.
          <br /> After completing the form and confirming payment details, you
          will receive a document pack including:
        </p>
        <ul>
          <li>Minutes of meeting</li>
          <li>Loan Deed</li>
          <li>General Security Agreement</li>
        </ul>
        <p>
          Your loan registration will be automatically lodged with the Personal
          Property Securities Register (PPSR) within 4 business days from
          payment.
        </p>
        <p>
          For accurate results, please ignore any autofill suggestions and type
          all your answers
        </p>
      </>
    );
  }
}
