/**
 * Typescript pattern to ensure exhaustive checks in switch/unions.
 *
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html
 */
export function assertNever(x: never, msg?: string): never {
  // tslint:disable-next-line:no-console
  console.log(msg || "unexpected object:", x);
  throw new Error(`${msg || "unexpected object:"} ${x}`);
}

/** Permissive type that is not any */
export type Unknown = {} | null;

/** A union member */
export type UnionMember = { kind: string } | number;

/**
 * Omits properties from a type.
 * See: https://www.typescriptlang.org/docs/handbook/advanced-types.html
 */
export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

/**
 * A type to extract all possible value types of a map / object.
 * For use extracting all possible survey value types.
 */
export type MapValue<T> = T[keyof T];

/**
 * All possible key values of a map / object.
 */
export type MapKey<T> = keyof T;
