import { assertNever } from "@hx/util/types";
import { observer } from "mobx-react";
import * as React from "react";
import { AboutUsPage } from "src/ui/page/about-us/about-us-page";
import { HowDoesThisWorkPage } from "src/ui/page/how-does-this-work/how-does-this-work-page";

import { UiConfig } from "../adl-gen/krodok/snappy/uiconfig";
import { AppStore } from "../stores/app-store";
import {
  FormPageLayout,
  FormPageLayoutProps
} from "../ui/layouts/form-page-layout/form-page-layout";
import { PageLayout } from "../ui/layouts/page-layout/page-layout";
import { CheckoutPage } from "../ui/page/checkout/checkout-page";
import { ConfirmPage } from "../ui/page/confirm/confirm-page";
import { HomePage } from "../ui/page/home/home-page";
import { LoanSuiteApplicationPage } from "../ui/page/loan-suite-application/loan-suite-application-page";
import { PrivacyPolicyPage } from "../ui/page/privacy-policy/privacy-policy-page";
import { ReviewPage } from "../ui/page/review/review-page";
import { TermsAndConditionsPage } from "../ui/page/terms-and-conditions/terms-and-conditions-page";
import { FormHeader } from "../ui/widgets/form-header/form-header";
import { ModalCanvas } from "../ui/widgets/modal/modal";
import { PublicFooter } from "../ui/widgets/public-footer/public-footer";

interface AppProps {
  /** Top level app state and actions */
  store: AppStore;
  /** UI configuration */
  uiconfig: UiConfig;
}

@observer
export class App extends React.Component<AppProps> {
  renderScreen() {
    const state = this.props.store.state;
    const onClose = () => this.props.store.navigateTo({ route: "home" });
    const Footer: FormPageLayoutProps["Footer"] = footerProps => {
      return <PublicFooter appLinks={this.props.store} {...footerProps} />;
    };

    if (state.kind === "home") {
      return (
        <PageLayout title="Krodok" appLinks={this.props.store}>
          <HomePage appLinks={this.props.store} store={this.props.store} />
        </PageLayout>
      );
    } else if (state.kind === "loan-suite-application") {
      const onBack = () => this.props.store.navigateTo({ route: "home" });
      const Header: FormPageLayoutProps["Header"] = headerProps => (
        <FormHeader onBack={onBack} onClose={onClose} {...headerProps} />
      );
      return (
        <FormPageLayout Header={Header} Footer={Footer}>
          <LoanSuiteApplicationPage
            questions={state.loanSuiteApplicationStore.questionSet}
            onSubmit={state.loanSuiteApplicationStore.onSubmit}
            setScroll={
              state.loanSuiteApplicationStore.setScrollToUnansweredQuestion
            }
          />
        </FormPageLayout>
      );
    } else if (state.kind === "review-and-confirm") {
      const onBack = () => window.history.back();
      const Header: FormPageLayoutProps["Header"] = headerProps => (
        <FormHeader onBack={onBack} onClose={onClose} {...headerProps} />
      );
      return (
        <FormPageLayout Header={Header} Footer={Footer}>
          <ReviewPage
            appLinks={this.props.store}
            questions={state.tocStore.getQuestionsSet()}
            onSubmit={state.tocStore.onSubmit}
          />
        </FormPageLayout>
      );
    } else if (state.kind === "checkout") {
      const onBack = () => window.history.back();
      const Header: FormPageLayoutProps["Header"] = headerProps => (
        <FormHeader onBack={onBack} onClose={onClose} {...headerProps} />
      );
      return (
        <FormPageLayout Header={Header} Footer={Footer}>
          <CheckoutPage
            cardElements={state.checkoutStore.cardSectionsSet}
            getClientSecret={state.checkoutStore.createPaymentIntent}
            checkPayment={state.checkoutStore.checkPaymentIntent}
            paymentFinished={state.checkoutStore.paymentFinished}
            stripePublicKey={this.props.uiconfig.stripe}
          />
        </FormPageLayout>
      );
    } else if (state.kind === "confirm") {
      return (
        <PageLayout title="Confirm" appLinks={this.props.store}>
          <ConfirmPage
            appLinks={this.props.store}
            application={state.applicationStore.getApplication()}
          />
        </PageLayout>
      );
    } else if (state.kind === "terms-and-conditions") {
      return (
        <PageLayout title="Terms and Conditions" appLinks={this.props.store}>
          <TermsAndConditionsPage />
        </PageLayout>
      );
    } else if (state.kind === "privacy-policy") {
      return (
        <PageLayout title="Privacy Policy" appLinks={this.props.store}>
          <PrivacyPolicyPage />
        </PageLayout>
      );
    } else if (state.kind === "learn-more") {
      return (
        <PageLayout title="Learn more" appLinks={this.props.store}>
          <HowDoesThisWorkPage />
        </PageLayout>
      );
    } else if (state.kind === "about-us") {
      return (
        <PageLayout title="About us" appLinks={this.props.store}>
          <AboutUsPage />
        </PageLayout>
      );
    } else {
      return assertNever(state, "unknown state");
    }
  }

  render() {
    return (
      <>
        <ModalCanvas />
        {this.renderScreen()}
      </>
    );
  }
}
