import { observer } from "mobx-react";
import * as React from "react";
import Calendar from "react-calendar";

//Global react calendar styles overwritten the defaults
require("./calendar-picker.css");

interface CalendarPickerProps {
  /** Date shown in the calendar */
  date: Date;
  /** Callback to change the value */
  onChangeDate(date: Date): void;
}

/**
 * A Calendar picker with custom styling
 */
@observer
export class CalendarPicker extends React.Component<CalendarPickerProps> {
  /** Renders the component */
  render() {
    return (
      <Calendar
        calendarType="US"
        onClickDay={this.props.onChangeDate}
        value={this.props.date}
      />
    );
  }
}
