import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";

import { UpdateApplicationResp } from "../../../adl-gen/krodok/snappy/api";
import { GridContainer } from "../../common/grid-container/grid-container";
import { ArrowRight } from "../../common/icon/icons";
import { Button } from "../../widgets/button/button";
import {
  Question,
  QuestionsList
} from "../../widgets/questions-list/questions-list";

const styles = require("./loan-suite-application-page.css");

/** Props for the component */
export interface LoanSuiteApplicationProps {
  questions: Question[];
  onSubmit(): Promise<UpdateApplicationResp>;
  setScroll(v: () => void): void;
}

/**
 * The Loan Suite Application page.
 */
@observer
export class LoanSuiteApplicationPage extends React.Component<
  LoanSuiteApplicationProps
> {
  firstUnansweredQuestionMarkerRef: Element | null;
  firstErroredQuestionMarkerRef: Element | null;
  submitButtonRef: Element | null;

  componentDidMount() {
    this.props.setScroll(this.scrollToFirstUnansweredQuestion);
  }

  render() {
    let firstUnansweredQuestion: Question | undefined;
    let firstErroredQuestion: Question | undefined;

    for (const question of this.props.questions) {
      if (!firstUnansweredQuestion && this.isQuestionUnanswered(question)) {
        firstUnansweredQuestion = question;
      }
      if (!firstErroredQuestion && this.isQuestionErrored(question)) {
        firstErroredQuestion = question;
      }
    }

    return (
      <div className={styles.container}>
        <QuestionsList
          className={styles.questionList}
          questions={this.props.questions}
          firstUnansweredQuestion={firstUnansweredQuestion}
          firstErroredQuestion={firstErroredQuestion}
          onCompletedAllQuestions={this.onCompletedAllQuestions}
          onFirstUnansweredQuestionMarkerRef={
            this.onFirstUnansweredQuestionMarkerRef
          }
          onFirstErroredQuestionMarkerRef={this.onFirstErroredQuestionMarkerRef}
          lastQuestionTailContent={
            <div className={styles.ctaContainer} ref={this.onSubmitButtonRef}>
              <GridContainer mdSpan={12} smSpan={8} xsSpan={4}>
                <h2>Review the information you have entered</h2>
                <p>
                  Scroll up and down this form to check the information you have
                  entered is correct before continuing to payment.
                </p>
              </GridContainer>
              <GridContainer
                xsSpan={4}
                smSpan={4}
                mdSpan={4}
                className={styles.ctaContainer}
              >
                <Button
                  Icon={ArrowRight}
                  iconPosition={"right"}
                  onClick={this.onSubmit}
                >
                  Confirm & Continue
                </Button>
              </GridContainer>
            </div>
          }
        />
      </div>
    );
  }

  /** Callback to submit all the form data */
  // tslint:disable-next-line: no-async-without-await
  onSubmit = async () => {
    let completedForm: boolean = true;
    for (const question of this.props.questions) {
      if (this.isQuestionUnanswered(question)) {
        completedForm = false;
      }
    }

    if (!completedForm) {
      this.scrollToFirstUnansweredQuestion();
    } else {
      const resp = await this.props.onSubmit();
      if (resp !== UpdateApplicationResp.success) {
        this.scrollToFirstErroredQuestion();
      }
    }
  };

  /** Callback to receive the submit button ref */
  onSubmitButtonRef = (ref: Element | null) => {
    this.submitButtonRef = ref;
  };

  /** Callback to receive the first unanswered question marker ref */
  onFirstUnansweredQuestionMarkerRef = (ref: Element | null) => {
    this.firstUnansweredQuestionMarkerRef = ref;
  };

  /** Scrolls to the first unanswered question */
  scrollToFirstUnansweredQuestion = (): void => {
    if (this.firstUnansweredQuestionMarkerRef) {
      const marker = ReactDOM.findDOMNode(
        this.firstUnansweredQuestionMarkerRef
      );
      if (marker instanceof Element) {
        marker.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  /** Callback to receive the first unanswered question marker ref */
  onFirstErroredQuestionMarkerRef = (ref: Element | null) => {
    this.firstErroredQuestionMarkerRef = ref;
  };

  /** Scrolls to the first errored question */
  scrollToFirstErroredQuestion() {
    if (this.firstErroredQuestionMarkerRef) {
      const marker = ReactDOM.findDOMNode(this.firstErroredQuestionMarkerRef);
      if (marker instanceof Element) {
        marker.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

  /** Check if a question has been marked as errored */
  isQuestionErrored(question: Question) {
    if (
      (question.kind === "text" && question.value.errorMessage !== undefined) ||
      (question.kind === "multi-text" &&
        question.value.props.inputs.some(input => {
          return input.errorMessage !== undefined;
        }))
    ) {
      return true;
    }
    return false;
  }

  /** Check if a question has been answered based on its kind */
  isQuestionUnanswered(question: Question) {
    if (
      (question.kind === "text" && question.value.value !== "") ||
      (question.kind === "multi-text" &&
        !question.value.props.inputs.some(input => {
          return input.value === "";
        })) ||
      (question.kind === "select" &&
        question.value.options.some(option => {
          return option.selected;
        })) ||
      (question.kind === "informational" && question.value.seen)
    ) {
      return false;
    }
    return true;
  }

  /** Callback to scroll to the submit button */
  onCompletedAllQuestions = () => {
    if (this.submitButtonRef) {
      const marker = ReactDOM.findDOMNode(this.submitButtonRef);
      if (marker instanceof Element) {
        marker.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
}
