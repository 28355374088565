import classnames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { ArrowLeft, CheckboxChecked, Cross } from "../../common/icon/icons";

const styles = require("./form-header.css");

/** Props for the component */
export interface FormHeaderProps {
  /** Additional classes to apply to the content */
  contentClassName?: string;
  /** Callback to go back */
  onBack(): void;
  /** Callback to close */
  onClose(): void;
  /** Callback to load example */
  debugOnLoadExample?(): void;
}

/**
 * Header to show when the menu is hidden under a hamburger.
 */
@observer
export class FormHeader extends React.Component<FormHeaderProps> {
  /** Whether the menu is expanded and shown */
  @observable.ref
  expanded: boolean = false;

  /** Renders the component */
  render() {
    return (
      <header>
        <GridContainer
          contentClassName={classnames(
            styles.content,
            this.props.contentClassName
          )}
          xsSpan={4}
        >
          <ArrowLeft size="xsmall" onClick={this.props.onBack} />
          {this.props.debugOnLoadExample ? (
            <CheckboxChecked
              size="xsmall"
              onClick={this.props.debugOnLoadExample}
            />
          ) : (
            undefined
          )}
          <Cross size="xsmall" onClick={this.props.onClose} />
        </GridContainer>
      </header>
    );
  }
}
