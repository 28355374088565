import classnames from "classnames";
import * as React from "react";

const icomoonStyle = require("./icon-font/style.css");
const style = require("./icon-font/krodok-icons-common.css");

export type IconSizes =
  | "xxsmall"
  | "xsmall"
  | "small"
  | "small-medium"
  | "medium"
  | "large"
  | "xlarge";

export type IconColors =
  | "black"
  | "primary"
  | "green"
  | "blue"
  | "orange"
  | "white";

export interface IconProps {
  /** Additional classes */
  className?: string;
  /** Color (Default: black) */
  color?: IconColors;
  /** Size otherwise medium */
  size?: IconSizes;
  /** Callback on click */
  onClick?(): void;
}
/** All icons for easy iterating */
export const ALL_ICONS: {
  name: string;
  component(props: IconProps): JSX.Element;
}[] = [];

function makeIconComponent(name: string): (props: IconProps) => JSX.Element {
  const iconComponent = (props: IconProps) => {
    const size = props.size || "xSmall";
    return (
      <span
        className={classnames(
          icomoonStyle[`krodok-icons-${name}`],
          style.icon,
          style[size],
          props.color && style[props.color],
          props.onClick && style.clickable,
          props.className
        )}
        onClick={props.onClick}
      />
    );
  };
  ALL_ICONS.push({
    name,
    component: iconComponent
  });
  return iconComponent;
}

export const LogoFull = makeIconComponent("logo-full");
export const Logo = makeIconComponent("logo");
export const Cross = makeIconComponent("x");
export const Plus = makeIconComponent("plus");
export const Phone = makeIconComponent("phone");
export const Mail = makeIconComponent("mail");
export const Lock = makeIconComponent("lock");
export const Location = makeIconComponent("location");
export const Group = makeIconComponent("group");
export const File = makeIconComponent("file");
export const Download = makeIconComponent("download");
export const Dot = makeIconComponent("dot");
export const CreditCard = makeIconComponent("credit-card");
export const CircleTick = makeIconComponent("circle-tick");
export const ChevronUp = makeIconComponent("chevron-up");
export const ChevronDown = makeIconComponent("chevron-down");
export const Building = makeIconComponent("building");
export const ArrowRight = makeIconComponent("arrow-right");
export const ArrowLeft = makeIconComponent("arrow-left");
export const CheckboxChecked = makeIconComponent("checkbox-on");
export const CheckboxUnChecked = makeIconComponent("checkbox-off");
