import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { SelectOption } from "../select-option/select-option";
import { TextInput } from "../text-input/text-input";

const styles = require("./select-question.css");

/** Question definition */
export interface SelectQuestionProps<T> {
  /** Additional classes to apply */
  className?: string;
  /** Text of the question */
  questionText: string;
  /** Hint text for the question */
  hintText?: string;
  /** Preset options */
  options: PresetOption<T>[];
  /** Custom option definition */
  customOption?: CustomOption<T>;
  /** Image to display underneath the question text */
  imageSource?: string;
  /** Callback when an option is selected */
  onSelect(option: T, isCustom: boolean): Promise<void>;
}

export class SelectQuestionPropsImpl<T> implements SelectQuestionProps<T> {
  options: PresetOptionImpl<T>[];
  questionText: string;

  onSelect: (option: T, isCustom: boolean) => Promise<void>;
  setter: (v: T | undefined) => void;

  getMatchingOption = (v: T | undefined) => {
    for (const option of this.options) {
      if (Object.is(option.value, v)) {
        return option;
      }
    }
    return undefined;
  };

  constructor(v: {
    questionText: string;
    getter(): T | undefined;
    setter(v: T | undefined): void;
    options: LabelAndValue<T>[];
  }) {
    this.questionText = v.questionText;
    this.setter = v.setter;
    this.options = v.options.map(
      o =>
        new PresetOptionImpl({
          label: o.label,
          value: o.value
        })
    );
    const currentValue = v.getter();
    const matchingOption = this.getMatchingOption(currentValue);
    if (matchingOption !== undefined) {
      matchingOption.selected = true;
    }

    // tslint:disable-next-line: no-async-without-await // THERE IS A WAY TO CONVERT THESE. I FORGOT HOW
    this.onSelect = async (option: T, _isCustom: boolean) => {
      const selectedOption = this.getMatchingOption(option);
      for (const o of this.options) {
        if (Object.is(selectedOption, o)) {
          o.selected = true;
        } else {
          o.selected = false;
        }
      }
      this.setter(option);
    };
  }
}

class LabelAndValue<T> {
  label: string;
  value: T;
}

class PresetOptionImpl<T> implements PresetOption<T> {
  label: string;
  value: T;

  @observable.ref
  selected: boolean = false;

  constructor(v: { label: string; value: T }) {
    this.label = v.label;
    this.value = v.value;
  }
}

/** Preset option */
export interface PresetOption<T> {
  /** Label to show */
  label: string;
  /** Hint text */
  hintText?: string;
  /** Value associated with option */
  value: T;
  /** Whether the option is selected */
  selected: boolean;
}

/** Custom option */
export interface CustomOption<T> {
  /** Label to show */
  label: string;
  /** Text entered for the custom option */
  textValue: string;
  /** Factory for the question type given the user custom input */
  makeCustomOptionValue(textValue: string): T;
  /** Whether the custom option is selected */
  selected: boolean;
}

/**
 * A question with a single answer chosen from a selection with an optional custom option.
 */
@observer
export class SelectQuestion<T> extends React.Component<SelectQuestionProps<T>> {
  /** Renders the custom option */
  renderCustomOption(option: CustomOption<T>) {
    return (
      <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
        <SelectOption
          className={styles.option}
          label={option.label}
          selected={option.selected}
          onChange={async selected => {
            if (selected) {
              await this.props.onSelect(
                option.makeCustomOptionValue(option.textValue),
                true
              );
            }
          }}
        />
        {option.selected ? (
          <TextInput
            className={styles.otherTextInput}
            type="text"
            value={option.textValue}
            placeholder="Other"
            onChange={async value => {
              await this.props.onSelect(
                option.makeCustomOptionValue(value),
                true
              );
            }}
          />
        ) : (
          undefined
        )}
      </GridContainer>
    );
  }

  /** Renders the question */
  render() {
    let inline: boolean;
    const columnSpan = 4;
    inline = false;

    const optionElems = this.props.options.map(option => (
      <SelectOption
        className={styles.option}
        key={option.label}
        label={option.label}
        selected={option.selected}
        onChange={async selected => {
          if (selected) {
            await this.props.onSelect(option.value, false);
          }
        }}
      />
    ));

    return (
      <div className={this.props.className}>
        <GridContainer xsSpan={4}>
          <h2 className={styles.questionText}>{this.props.questionText}</h2>
          {this.props.hintText && (
            <div className={styles.hintText}>{this.props.hintText}</div>
          )}
          {this.props.imageSource ? (
            <img className={styles.image} src={this.props.imageSource} />
          ) : (
            undefined
          )}
        </GridContainer>
        {inline ? (
          optionElems.map((elem, index) => (
            <GridContainer
              key={index}
              xsSpan={columnSpan}
              smSpan={columnSpan}
              mdSpan={columnSpan}
            >
              {elem}
            </GridContainer>
          ))
        ) : (
          <GridContainer
            xsSpan={columnSpan}
            smSpan={columnSpan}
            mdSpan={columnSpan}
          >
            {optionElems}
          </GridContainer>
        )}
        <div>
          {this.props.customOption
            ? this.renderCustomOption(this.props.customOption)
            : null}
        </div>
      </div>
    );
  }
}
