import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { GridContainer } from "../../common/grid-container/grid-container";
import { TextInput, TextInputProps } from "../text-input/text-input";

const styles = require("./text-question.css");

/** Question definition */
export interface TextQuestionProps {
  /** Additional classes to apply */
  className?: string;
  /** Text of the question */
  questionText: string;
  /** Hint text for the question */
  hintText?: string;
  /** Placeholder text */
  placeholderText?: string;
  /** Entered input value */
  value: string;
  /** Callback when the value changes */
  onChange(value: string): void;
  /** Specifies the input type */
  inputType?: TextInputProps["type"];
  /** Specifies if the question should be autocompleted or not */
  autoComplete?: "on" | "off" | "new-password";
  /** Specificies the name of the input */
  name?: string;
  /** Input description text */
  inputDescription?: JSX.Element | string;
  /** Error message */
  errorMessage?: string;
  /** Minimum length */
  minLength?: number;
  /** Maximum length */
  maxLength?: number;
}

export class TextQuestionImpl implements TextQuestionProps {
  @observable.ref
  value: string;

  @observable.ref
  errorMessage: string | undefined = undefined;

  questionText: string;
  placeholderText?: string;
  hintText?: string;
  name?: string;
  inputType?: TextInputProps["type"];
  minLength?: number;
  maxLength?: number;
  setter: (v: string) => void;

  constructor(v: {
    questionText: string;
    placeholderText: string;
    hintText?: string;
    name: string;
    inputType?: TextInputProps["type"];
    minLength?: number;
    maxLength?: number;
    getter(): string;
    setter(v: string): void;
    getError?(): string | undefined;
  }) {
    this.questionText = v.questionText;
    this.placeholderText = v.placeholderText;
    this.hintText = v.hintText;
    this.name = v.name;
    this.value = v.getter();
    this.errorMessage = v.getError === undefined ? undefined : v.getError();
    this.setter = v.setter;
    this.inputType = v.inputType;
    this.minLength = v.minLength;
    this.maxLength = v.maxLength;
  }

  onChange = (v: string): void => {
    this.value = v;
    this.setter(v);
  };
}

/**
 * A question with free text response.
 */
@observer
export class TextQuestion extends React.Component<TextQuestionProps> {
  /** Renders the question */
  render() {
    return (
      <div className={this.props.className}>
        <GridContainer xsSpan={4} className={styles.questionText}>
          <h2 className={styles.questionText}>{this.props.questionText}</h2>
          {this.props.hintText && <div>{this.props.hintText}</div>}
        </GridContainer>
        <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
          <TextInput
            className={styles.input}
            autoComplete={this.props.autoComplete}
            name={this.props.name}
            type={this.props.inputType || "text"}
            placeholder={this.props.placeholderText || "Please enter"}
            value={this.props.value}
            onChange={this.props.onChange}
            errorMessage={this.props.errorMessage}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
          />
          {this.props.inputDescription}
        </GridContainer>
      </div>
    );
  }
}
