import * as React from "react";

export class ExistingCompanySummary extends React.Component {
  render() {
    return (
      <>
        <p>
          Your document pack will contain three extra documents as you're
          registering an existing loan:
        </p>
        <ul>
          <li>Letter of demand</li>
          <li>Company Response to letter of demand</li>
          <li>Deed of forbearance</li>
        </ul>
        <p>
          It is necessary for new “consideration” to be given in order to get a
          new security over an existing loan that is legally enforceable.
          Consideration simply means that each party is giving something to each
          other for a bargain to be struck. The way to do that is for the owner
          lenders to call on the company to repay the entire loan. Any loan that
          is undocumented, or has no terms about repayment, is repayable on the
          demand of the lender. In turn, the company informs the owner lenders
          that it cannot or does not want to repay the loan at this time and
          requests that the owner lenders do not enforce their rights under the
          loan (forbearance) on the basis that:
        </p>
        <ul>
          <li>
            the loan is restated in a new loan document, which will also cover
            any money loaned after the date of the document; and
          </li>
          <li>
            a general security agreement is entered to secure the company's
            obligation to repay the loan
          </li>
        </ul>
        <p>
          it is ideal for the security over money already loaned, even with the
          new consideration, to be registered for a period of six (6) months to
          "harden" and be enforcable.
        </p>
      </>
    );
  }
}
