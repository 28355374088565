import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent
} from "@stripe/stripe-js";
import classNames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { CARD_ELEMENT_OPTIONS } from "./stripe-checkout-style";
const styles = require("./stripe-checkout.css");

export interface CardSectionProps {
  cardType: "number" | "expiry" | "cvc";
  label: string;
  errorMessage?: string;
  complete: boolean;
  onChange(event: StripeElementChangeEvent): void;
}

export class CardSectionImpl implements CardSectionProps {
  @observable.ref
  errorMessage: string | undefined = undefined;
  @observable.ref
  complete: boolean = false;

  cardType: "number" | "expiry" | "cvc";
  label: string;
  setter: (v: StripeElementChangeEvent) => void;

  constructor(v: {
    cardType: "number" | "expiry" | "cvc";
    label: string;
    setter(v: StripeElementChangeEvent): void;
    getError?(): string | undefined;
    getComplete(): boolean;
  }) {
    this.cardType = v.cardType;
    this.label = v.label;
    this.setter = v.setter;
    this.errorMessage = v.getError === undefined ? undefined : v.getError();
    this.complete = v.getComplete()
  }

  onChange = (v: StripeElementChangeEvent): void => {
    this.complete = v.complete;
    this.errorMessage = v.error?.message;
    this.setter(v);
  };
}

@observer
export class CardSection extends React.Component<CardSectionProps> {
  render() {
    return (
      <div className={classNames(styles.container)}>
        <label className={classNames(styles.smallLabel)}>
          {this.props.label}
        </label>
        {this.props.cardType === "number" && (
          <CardNumberElement
            className={classNames(styles.stripeInput, styles.textInput)}
            options={CARD_ELEMENT_OPTIONS}
            onChange={(event: StripeCardNumberElementChangeEvent) =>
              this.props.onChange(event)
            }
          />
        )}
        {this.props.cardType === "cvc" && (
          <CardCvcElement
            className={classNames(styles.stripeInput, styles.textInput)}
            options={CARD_ELEMENT_OPTIONS}
            onChange={(event: StripeCardCvcElementChangeEvent) =>
              this.props.onChange(event)
            }
          />
        )}
        {this.props.cardType === "expiry" && (
          <CardExpiryElement
            className={classNames(styles.stripeInput, styles.textInput)}
            options={CARD_ELEMENT_OPTIONS}
            onChange={(event: StripeCardExpiryElementChangeEvent) =>
              this.props.onChange(event)
            }
          />
        )}
        {this.props.errorMessage && <label className={classNames(styles.errorMessage)}>{this.props.errorMessage}</label>}
      </div>
    );
  }
}
