import { observable } from "mobx";

import {
  ApplicationReq,
  UpdateApplicationResp
} from "../adl-gen/krodok/snappy/api";
import {
  Application,
  ApplicationId,
  CompanyType
} from "../adl-gen/krodok/snappy/db";
import { Service } from "../service/service";

import { AppRouter } from "./shared/app-router";

export class ApplicationStore {
  @observable.deep applicationId: string | undefined = undefined;
  @observable.deep application: Application | undefined = undefined;

  constructor(
    private readonly appRouter: AppRouter,
    readonly service: Service
  ) {}

  /** Set the currently selected application by its id */
  setApplication = async (applicationId: string): Promise<void> => {
    this.applicationId = applicationId;
    const resp = await this.service.queryApplication({
      id: applicationId
    });
    if (resp.kind === "invalidApplicationId") {
      throw new ReferenceError("No application found for id");
    } else {
      this.application = resp.value;
      this.applicationId = applicationId;
    }
  };

  /** Get the currently selected application */
  getApplication = (): Application => {
    if (this.application === undefined) {
      throw new Error("Application is undefined");
    }
    return this.application;
  };

  /** Clear the currently viewed application */
  clearApplication = () => {
    this.application = undefined;
    this.applicationId = undefined;
  };

  /** Update an application given by its id */
  updateApplication = async (
    application: ApplicationReq
  ): Promise<UpdateApplicationResp> => {
    if (!this.applicationId) {
      throw new Error("Application Id not set");
    }
    const resp = await this.service.updateApplication({
      id: this.applicationId,
      value: {
        companyType: application.companyType,
        contactEmail: application.contactEmail || "",
        applicationData: application.applicationData || "{}",
        acceptedTOS: application.acceptedTOS || false
      }
    });
    return resp;
  };

  /** Create an application */
  createApplication = async (
    companyType: CompanyType
  ): Promise<ApplicationId | undefined> => {
    const resp = await this.service.createApplication(companyType);
    return resp;
  };

  /** Create an application for a new company */
  createNewCompanyApplication = async (): Promise<void> => {
    const resp = await this.createApplication(CompanyType.new);
    if (resp !== undefined) {
      await this.appRouter.navigateToLoanSuiteApplication(resp);
    }
  };

  /** Create an application for an existing company */
  createExistingCompanyApplication = async (): Promise<void> => {
    const resp = await this.createApplication(CompanyType.existing);
    if (resp !== undefined) {
      await this.appRouter.navigateToLoanSuiteApplication(resp);
    }
  };
}
