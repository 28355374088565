import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

const styles = require("./select-option.css");

/** Props for the select option */
export interface SelectOptionProps {
  /** Additional classes to apply */
  className?: string;
  /** Label to show */
  label: string;
  /** Whether it is selected */
  selected: boolean;
  /** Callback when selection changes */
  onChange(selected: boolean): void;
}

/** A single option in the spirit of a radio button */
@observer
export class SelectOption extends React.Component<SelectOptionProps> {
  /** Renders the option */
  render() {
    return (
      <div
        className={classnames(
          styles.option,
          this.props.selected && styles.selected,
          this.props.className
        )}
        onClick={this.onChange}
      >
        {this.props.label}
      </div>
    );
  }

  /** Callback upon change */
  onChange = () => {
    this.props.onChange(!this.props.selected);
  };
}
