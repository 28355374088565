/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as adlc_config_typescript } from "./adlc/config/typescript";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_adminui_api } from "./common/adminui/api";
import { _AST_MAP as common_adminui_db } from "./common/adminui/db";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_db } from "./common/config/db";
import { _AST_MAP as common_config_emailer } from "./common/config/emailer";
import { _AST_MAP as common_config_frontend } from "./common/config/frontend";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as krodok_snappy_api } from "./krodok/snappy/api";
import { _AST_MAP as krodok_snappy_db } from "./krodok/snappy/db";
import { _AST_MAP as krodok_snappy_uiconfig } from "./krodok/snappy/uiconfig";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_dynamic } from "./sys/dynamic";
import { _AST_MAP as sys_types } from "./sys/types";

export const ADL: { [key: string]: ScopedDecl } = {
  ...adlc_config_typescript,
  ...common,
  ...common_adminui_api,
  ...common_adminui_db,
  ...common_config_aws,
  ...common_config_db,
  ...common_config_emailer,
  ...common_config_frontend,
  ...common_config_log,
  ...common_db,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...krodok_snappy_api,
  ...krodok_snappy_db,
  ...krodok_snappy_uiconfig,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_dynamic,
  ...sys_types,
};

export const RESOLVER = declResolver(ADL);
