import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";

import { AppLinks } from "../../../stores/shared/app-links";
import { GridContainer } from "../../common/grid-container/grid-container";
import { Button } from "../../widgets/button/button";
import {
  Question,
  QuestionsList
} from "../../widgets/questions-list/questions-list";

const styles = require("./review-page.css");

/** Props for the component */
export interface ReviewProps {
  /** App links */
  appLinks: AppLinks;
  /** Terms and Conditions */
  questions: Question[];
  /** Submit handler */
  onSubmit(): void;
}

/**
 * The Review page.
 */
@observer
export class ReviewPage extends React.Component<ReviewProps> {
  submitButtonRef: Element | null;

  /** Renders the component */
  render() {
    return (
      <div className={styles.container}>
        <GridContainer xsSpan={4} mdSpan={12} className={styles.title}>
          <h1>Review and Confirm</h1>
          <p>
            All boxes below must be checked to complete the forms and continue
            to payment
          </p>
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={12} className={styles.statement}>
          <p>
            The information you have provided will be used to generate the
            necessary documentation in order to secure your loan and register it
            on the Personal Property Securities Register (PPSR).
          </p>
        </GridContainer>
        <GridContainer xsSpan={4} mdSpan={12}>
          <QuestionsList
            className={classNames(styles.questionList)}
            questions={this.props.questions}
            onCompletedAllQuestions={this.onCompletedAllQuestions}
            lastQuestionTailContent={
              <div className={styles.ctaContainer} ref={this.onSubmitButtonRef}>
                <GridContainer xsSpan={4} smSpan={4} mdSpan={4}>
                  <Button onClick={this.onSubmit}> Continue to payment </Button>
                </GridContainer>
              </div>
            }
          />
        </GridContainer>
      </div>
    );
  }

  onSubmit = async () => {
    return this.props.onSubmit();
  };

  /** Callback to receive the submit button ref */
  onSubmitButtonRef = (ref: Element | null) => {
    this.submitButtonRef = ref;
  };

  /** Callback to scroll to the submit button */
  onCompletedAllQuestions = () => {
    if (this.submitButtonRef) {
      const marker = ReactDOM.findDOMNode(this.submitButtonRef);
      if (marker instanceof Element) {
        marker.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
}
