import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
const matthew = require("../../../assets/images/matthew.jpg");

import { GridContainer } from "../../common/grid-container/grid-container";

const styles = require("./about-us-page.css");

/**
 * The How Does This Work page.
 */
@observer
export class AboutUsPage extends React.Component {
  renderTeamSection() {
    return (
      <GridContainer
        xsSpan={4}
        smSpan={8}
        mdSpan={12}
        className={classNames(styles.section)}
      >
        <div className={classNames(styles.teamMemberProfile)}>
          <img className={classNames(styles.employeeImage)} src={matthew} />
          <div className={classNames(styles.teamMemberBio)}>
            <div className={classNames(styles.teamMemberName)}>
              <h2>Matthew Kelly</h2>
              <a
                target="_"
                href="https://www.linkedin.com/in/matthew-kelly-24876719"
                className={classNames(styles.faLinkedin)}
              />
            </div>
            <p>
              <strong className={classNames(styles.highlight)}>
                Founder and Managing Director
              </strong>{" "}
            </p>
          </div>
        </div>
        <p className={classNames(styles.quote)}>
          I was a lawyer for more than 20 years, working at some of the biggest
          firms in Australia. I specialised in corporate insolvency and
          restructuring. I advised and acted for business owners and directors,
          insolvency practitioners and lenders. I was recognised by several of
          the “best lawyers” publications as a specialist in this field. I was a
          longstanding member of Australian and international professional
          bodies for corporate insolvency, restructuring and turnaround.
        </p>
        <p className={classNames(styles.quote)}>
          During my time as a lawyer, I had many small-business owners come to
          me who thought they may have an issue with their business and were
          seeking advice as to how they could fix that problem. Too often I had
          the unfortunate task of telling those owners that they had a
          significant problem and there was not much that they could reasonably
          commercially do to save their business. Those owners overwhelmingly
          lost everything that they had during the process. I got frustrated by
          this situation and thought that there had to be a better way to do
          things.
        </p>
        <p className={classNames(styles.quote)}>
          A trend emerged that those small-business had invested up to hundreds
          of thousands of dollars of their own money (and sometimes even more)
          into their businesses over the years, but they had not protected that
          investment as a secured loan. If that investment had been protected as
          a secured loan, registered on the register maintained pursuant to the
          Personal Property Securities Act, small business owners would have
          been far better able to protect their assets and their business and to
          use the protections available under the insolvency regime to try to
          rehabilitate companies in financial distress. Small businesses need to
          protect their investment like they are a bank because the bank never
          loses.
        </p>
        <p className={classNames(styles.quote)}>
          It became apparent that more than 90% of small businesses do not have
          this protection, which means that more than 90% of businesses stand
          little chance of being able to successfully navigate through the
          insolvency system without owners spending significantly more of their
          own money. That is a missed opportunity as the insolvency regime is
          set up to help small business reset. The question was why it was the
          case and what could be done to help small-business owners get this
          vital protection. The reason that small-business owners don’t get the
          protection they need is either because they don’t seek advice when
          they set up their business, or they get advice to get protection but
          the cost is considered to be too high to put it in place.
        </p>
        <p className={classNames(styles.quote)}>
          I am passionate about protecting small business owners with affordable
          and easily accessible tools to empower them with options under our
          legal system to survive and thrive. It is part of my mission that
          small-business owners and their advisers have access to a hub of
          information that they can consult from the time that the set up their
          business and throughout their journey.
        </p>
        <p className={classNames(styles.quote)}>
          If you have any questions, please don’t hesitate to contact me and the
          team by email to{" "}
          <a
            className={classNames(styles.highlight)}
            href="mailto:inquiries@krodok.com.au"
          >
            inquiries@krodok.com.au
          </a>
          .
        </p>
      </GridContainer>
    );
  }

  /** Renders the component */
  render() {
    return (
      <div className="page-layout">
        <div className={styles.container}>
          <GridContainer xsSpan={4} smSpan={8} mdSpan={12}>
            <h1 className={classNames(styles.pageTitle)}>About us</h1>
            <p className={classNames(styles.summary)}>
              <strong className={classNames(styles.highlight)}>krodok</strong>{" "}
              is a digital tool designed for you. if you have invested in your
              company and want to secure your future, you need{" "}
              <strong className={classNames(styles.highlight)}>krodok</strong>.
              it automatically generates legally-enforceable documents that
              raises you to being a first-ranking creditor of your business
              without the fuss or expense of dealing with lawyers
            </p>
            <h2 className={classNames(styles.section)}>Our Team</h2>
          </GridContainer>
          {this.renderTeamSection()}
        </div>
      </div>
    );
  }
}
