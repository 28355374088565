import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { ArrowRight, IconProps } from "../../common/icon/icons";

const styles = require("./clickable-tile.css");
const fonts = require("../../common/fonts/fonts.css");

/** Props for a clickable tile component */
export interface ClickableTileProps {
  /** Additional calasses to apply */
  className?: string;
  /** Icon to render */
  Icon: React.ComponentType<IconProps>;
  /** Title to render */
  title: string;
  /** Text to render */
  text: string;
  /** Call to action text */
  ctaText: string;
  /** Href link to follow */
  href?: string;
  /** onClick callback to execute */
  onClick?(): Promise<void>;
}

/**
 * Clickable tile
 */
@observer
export class ClickableTile extends React.Component<ClickableTileProps> {
  /** Renders the component */
  render() {
    return (
      <div className={styles.containerWrapper}>
        <a href={this.props.href} onClick={this.props.onClick}>
          <div className={classnames(styles.container, this.props.className)}>
            <div className={styles.content}>
              <h2 className={classnames(fonts.semibold, styles.title)}>
                {this.props.title}
              </h2>
              <div className={styles.text}>{this.props.text}</div>
            </div>
            <div className={styles.cta}>
              <div className={styles.icon}>
                <this.props.Icon color="primary" size="small-medium" />
              </div>
              <div className={styles.ctaText}>
                {this.props.ctaText}
                <ArrowRight color="primary" size="xsmall" />
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
